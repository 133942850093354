import React, { useEffect, useState } from 'react';
import Select from "react-select";
import ManageToken from '../../Token';
import Breadcrumbs from '../Breadcrumbs/Breadcrumbs';
import LogoutUser from '../Logout/Logout';


function EnrollmentTools(props: any) {
    const isAdmin = false;
    const token = new ManageToken();
    const [courseNames, setCourseNames] = useState([{}])
    const [selectedCourse, setSelectedCourse] = useState(null);
    const [sessionNames, setSessionNames] = useState([{}])
    const [selectedCourseSession, setSelectedCourseSession] = useState(null); 
    const [emails, setEmails] = useState(null);
    const [userIds, setUserIds] = useState([{}]);
    const [inActiveUsersCount, setInActiveUsersCount] = useState(0);
    const [inActiveEmails, setInActiveEmails] = useState<Array<InactiveEmail>>([])
    const [isEnrollMsgHidden, setIsEnrollMsgHidden] = useState(true);
    const [EnrollWarning, setEnrollWarning] = useState(true);
    const [inActiveEmailsHidden, setInActiveEmailsHidden] = useState(true)   
    const [isSessionDisabled, setIsSessionDisabled] = useState(true);
    const [isEmailsTxtBoxDisabled, setIsEmailsTxtBoxDisabled] = useState(true);
    const [isValidateEmailBtnDisabled, setisValidateEmailBtnDisabled] = useState(true)
    const [isSubmitBtnDisabled, setIsSubmitBtnDisabled] = useState(true)
    const [responseMessage, setResponseMessage] = useState(null);
    const [isCourseLoading, setIsCourseLoading] = useState(false);
    const [isSessionLoading, setIsSessionLoading] = useState(false);
    const [emailsSpinnerHidden, setEmailsSpinnerHidden] = useState(true)
    const [enrollSpinnerHidden, setEnrollSpinnerHidden]=useState(true)
    interface InactiveEmail {
        Email: string
    }
    var tempArrCourse = [{}];
    var tempArrSession = [{}];
    var tempArrUserIds = [{}];
    const tempArrInactiveEmails: InactiveEmail[] = []

    function RefreshComponent() {
        window.location.reload();
    }
    function handleCourseChange(event: any)
    {
        setSelectedCourse(event.value)
        if (event.value !== null && event.value !== undefined) {
            setIsSessionDisabled(false);
            getSessionDetails(event.value);
        }
    }

    function handleCourseSessionChange(event: any) {
        setSelectedCourseSession(event.value)
        if (event.value !== null && event.value !== undefined) {
            setIsEmailsTxtBoxDisabled(false);}
    }

    function handleEmailChange(event: any) {
        setEmails(event.target.value)
        setisValidateEmailBtnDisabled(false)
    }


    useEffect(() => {
        getCourseDetails();
    }, [])


    function getCourseDetails() {
        setIsCourseLoading(true)
        var courseType = process.env.REACT_APP_SERVER_URL_COURSE_TYPE ? process.env.REACT_APP_SERVER_URL_COURSE_TYPE?.toString() : "";
        var getCourseDetails = process.env.REACT_APP_SERVER_URL_GET_COURSES ? process.env.REACT_APP_SERVER_URL_GET_COURSES : "";
        const getCourseData = async () =>
            await fetch(getCourseDetails + courseType, {
                headers: {
                    "Authorization": "Bearer " + token.GetToken()
                }
            })
                .then(res => res.json())
                .then(
                    (result) => {
                        for (let i = 0; i < result.length; i++) {
                            tempArrCourse.push({ value: result[i].id, label: result[i].name })

                        }
                        tempArrCourse.shift();
                        setCourseNames(tempArrCourse)
                        setIsCourseLoading(false)
                        console.log(courseNames)
                    },

                    (error) => {
                        alert("API server error to load course data!");
                        setIsCourseLoading(false)
                    }
                )
        getCourseData();
    }

    function getSessionDetails(selectedCourseId: string) {
        setIsSessionLoading(true)
        var getSessionDetails = process.env.REACT_APP_SERVER_URL_GET_SESSIONS ? process.env.REACT_APP_SERVER_URL_GET_SESSIONS : "";
        const getSessionData = async () =>
            await fetch(getSessionDetails + selectedCourseId, {
                headers: {
                    "Authorization": "Bearer " + token.GetToken()
                }
            })
                .then(res => res.json())
                .then(
                    (result) => {
                        for (let i = 0; i < result.length; i++) {
                            console.log(result[i])
                            tempArrSession.push({ value: result[i].id, label: result[i].name })
                        }
                        tempArrSession.shift();
                        console.log(tempArrSession)
                        setSessionNames(tempArrSession)
                        setIsSessionLoading(false)
                        console.log(sessionNames)
                    },

                    (error) => {
                        alert("API server error to load session data!");
                        setIsSessionLoading(false)
                    }
                )
        getSessionData();
    }
    function handleValidateEmail(e:any) {
        setInActiveEmailsHidden(true);
        setEmailsSpinnerHidden(false)
        var handleValidateEmail = process.env.REACT_APP_SERVER_URL_VALIDATE_EMAIL ? process.env.REACT_APP_SERVER_URL_VALIDATE_EMAIL : "";
        const ValidateEmail = async () =>
            await fetch(handleValidateEmail + emails, {
                headers: {
                    "Authorization": "Bearer " + token.GetToken()
                }
            })
                .then(res => res.json())
                .then(
                    (result) => {
                        if (result.activeUserIds.length > 0) {                           
                            for (let i = 0; i < result.activeUserIds.length; i++) {
                                tempArrUserIds.push(result.activeUserIds[i])
                            }
                            tempArrUserIds.shift();
                            setUserIds(tempArrUserIds)
                            setIsSubmitBtnDisabled(false);
                        }

                        if (result.inActiveEmails.length > 0) {
                            for (let i = 0; i < result.inActiveEmails.length; i++) {

                                tempArrInactiveEmails.push({ Email: result.inActiveEmails[i] });
                            }
                            setInActiveEmails(tempArrInactiveEmails)
                            setInActiveUsersCount(result.inActiveUsersCount);
                            setInActiveEmailsHidden(false);
                        }
                        setEmailsSpinnerHidden(true)
                    },

                    (error) => {
                        alert("API server error to validate email data!");
                        setEmailsSpinnerHidden(true)
                    }
                )
        ValidateEmail();
    }
    function handleSubmit(e: any) {
        setEnrollSpinnerHidden(false)
        e.currentTarget.disabled = true;
        var handleSubmit = process.env.REACT_APP_SERVER_URL_SUBMIT_ENROLLMENT ? process.env.REACT_APP_SERVER_URL_SUBMIT_ENROLLMENT : "";

        const handleSubmitEnrollment = async () =>
            await fetch(handleSubmit + selectedCourse + "&courseSession=" + selectedCourseSession,
                {
                    method: "POST",
                    body: JSON.stringify(userIds),
                    headers: {
                        "Authorization": "Bearer " + token.GetToken(),
                        "Content-Type": "application/json"
                    }
                })
                .then(res => res.json())
                .then(
                    (result) => {
                        if (result.enrollmentId !== null)
                        {
                            setIsEnrollMsgHidden(false);

                        }
                        if (result.enrollmentId == '' || result.enrollmentId == null)
                        { setEnrollWarning(false) }

                        setResponseMessage(result.responseMessage)
                        setEnrollSpinnerHidden(true)
                    },

                    (error) => {
                        alert("API server error to load data!");
                        setEnrollSpinnerHidden(true)
                    }
                )
        handleSubmitEnrollment();
    }
    return (
        <div>

            <div className="loading-indicator" hidden={emailsSpinnerHidden}>
                <div className="spinner-border text-primary">
                    <span className="sr-only">Loading...</span>
                </div>
            </div>
            <div className="loading-indicator" hidden={enrollSpinnerHidden}>
                <div className="spinner-border text-primary">
                    <span className="sr-only">Loading...</span>
                </div>
            </div>
            <Breadcrumbs route="/courseAssignment/enrollment" name="Session Enrollment" />
            <LogoutUser isAdmin={isAdmin} />

            <div className="container" >
                <div className="row">
                    <div className="col">
                        <h2>Instructor-Led Course Enrollment</h2>
                        <hr></hr>
                        <div className="row">
                            <div className="col">
                                <h6>Course</h6>
                                <Select placeholder="Select" isLoading={isCourseLoading} options={isCourseLoading ? [] : courseNames} noOptionsMessage={() => 'No options Found'} onChange={handleCourseChange} />
                            </div>
                            <div className="col">
                                <h6>Course Session</h6>
                                <Select placeholder="Select" isLoading={isSessionLoading} options={isSessionLoading ? [] : sessionNames} noOptionsMessage={() => 'No options Found'} onChange={handleCourseSessionChange} isDisabled={isSessionDisabled} />
                            </div>
                        </div>
                        <br />
                        <h6>Emails (separate with commas)</h6>
                        <textarea className="courseEmails" rows={10} cols={70} onChange={handleEmailChange} disabled={isEmailsTxtBoxDisabled}
                            placeholder="ex. john.doe@commscope.com, jane.foe@commscope.com" autoFocus /><br />
                        <button className="btn btn-secondary" disabled={isValidateEmailBtnDisabled} onClick={handleValidateEmail}>Validate Emails</button><br/>
                        <div hidden={inActiveEmailsHidden} className="alert alert-info alert-dismissible fade show" role="alert">
                            Number of Inactive user email(s) - {inActiveUsersCount}
                            {inActiveEmails.map((temp) => (<li>{temp.Email}</li>))}
                        </div>
                        <hr></hr>
                        <button className="btn btn-primary" disabled={isSubmitBtnDisabled} onClick={handleSubmit}>Enroll Students</button>&nbsp;&nbsp;
                        <button className="btn btn-secondary" onClick={RefreshComponent}>Reset</button>
                        <br/><br/><div hidden={isEnrollMsgHidden} className="alert alert-success" role="alert">
                            <strong>{responseMessage}</strong>
                        </div>
                        <div hidden={EnrollWarning} className="alert alert-danger" role="alert">
                            <strong>{responseMessage}</strong>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
}
export default EnrollmentTools;