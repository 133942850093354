import React, { useState,useEffect, useRef } from 'react';
import Header from './components/Header';
//import Breadcrumbs from './components/Breadcrumbs/Breadcrumbs';
import Footer from './components/Footer';
import Requests, { RequestsRouter } from './components/Requests/Requests';
//import LogoutUser from './components/Logout/Logout';
import { BrowserRouter, HashRouter, Route, Routes } from 'react-router-dom';
import UserRequestDetails from './components/UserRequestDetails/UserRequestDetails';
import UploadFile, { UploadFileRouter } from './components/UploadFile/UploadFile';
import EnrollmentTools from './components/EnrollmentTools/EnrollmentTools';
import AdministratorTools from './components/AdministratorTools/AdministratorTools';
import './App.css';
import InstructorTools, { InstructorToolsRouter } from './components/InstructorTools/InstructorTools';
import SignInSheetComponent from './components/SignInSheet/SignInSheet';
import SurveyMetrics from './components/SurveyMetrics/SurveyMetrics';
import Analytics from './components/Analytics';
import { MsalProvider, useMsal,MsalAuthenticationTemplate, useAccount } from '@azure/msal-react';
import { EventType, InteractionType, InteractionRequiredAuthError, InteractionStatus, BrowserAuthError, PublicClientApplication } from '@azure/msal-browser';
import { b2cPolicies, loginRequest, msalConfig, protectedResources } from './authConfig';

const Pages:any = () => {

    const { instance, inProgress, accounts } = useMsal();
    //const [apiData, setApiData] = useState(null);
    const clientID = process.env.REACT_APP_B2C_CLIENTID ? process.env.REACT_APP_B2C_CLIENTID.toString() : ""

    const authRequest = {
        scopes: ["offline_access", "openid", ...protectedResources.commscopeUniversityAPIList.scopes.write, ...protectedResources.commscopeUniversityAPIList.scopes.read],
    };
    const account = useAccount(accounts[0] || {});
    const [token, setToken] = useState('');
    //const token = useRef('');
    //const loginroles = useRef('');
    const [loginroles, setLoginroles]=useState('')

    useEffect(() => {

        if (accounts !== null && accounts.length > 0) {
            const accessTokenRequest = {
                scopes: ["offline_access", "openid", ...protectedResources.commscopeUniversityAPIList.scopes.write, ...protectedResources.commscopeUniversityAPIList.scopes.read],
                account: accounts[0],
            };
            if (inProgress === InteractionStatus.None && instance !== null) {
                instance
                    .acquireTokenSilent(accessTokenRequest)
                    .then((accessTokenResponse) => {
                        // Acquire token silent success
                        let accessToken = accessTokenResponse.accessToken;
                        if (accessToken !== '') {
                            //window.sessionStorage.setItem("AuthorizationToken", accessToken);
                            //token.current = accessToken;
                            window.sessionStorage.setItem("AuthorizationToken", accessToken);
                            setToken(accessToken);
                            var roles = [accessTokenResponse.account?.idTokenClaims?.roles][0];
                            if ((typeof roles === 'string')) {
                                const result: string = roles;
                                //window.sessionStorage.setItem("Roles", result);
                                //loginroles.current = result;
                                setLoginroles(result);
                                //if (result.toLowerCase() === roleName.toLowerCase()) {
                                //    isCommscopeUniveristyRolePresent = true;
                                //}
                            }
                            else {
                                if (roles !== undefined) {
                                    roles.forEach((e1: any) => {
                                        //loginroles.current = e1;
                                        setLoginroles(e1);
                                    });
                                }
                            }

                            //else {

                            //    if (roles !== undefined) {
                            //        roles.forEach((e1: any) => {
                            //            if (e1.toString().toLowerCase() == roleName.toLowerCase()) { isCommscopeUniveristyRolePresent = true; }
                            //        });
                            //    }
                            //}
                        }


                        // Call your API with token
                        //callApi(accessToken).then((response) => {
                        //    setApiData(response);
                        //});
                    })
                    .catch((error) => {
                        if (error instanceof InteractionRequiredAuthError || error instanceof BrowserAuthError) {
                            instance.acquireTokenRedirect(accessTokenRequest);
                        }
                        console.log(error);
                    });
            }
           
        }
    }, [instance, accounts, inProgress]);


    //useEffect(() => {
    if (token !== '') {
        let isCommscopeUniveristyRolePresent = false;
        var roleName = process.env.REACT_APP_ROLE_NAME ? process.env.REACT_APP_ROLE_NAME.toString() : "";
        if (loginroles.toLowerCase() === roleName.toLowerCase()) {
            isCommscopeUniveristyRolePresent = true;
            window.sessionStorage.setItem("AuthorizationToken", token);
        }

        if (isCommscopeUniveristyRolePresent) {
            return (
                <MsalAuthenticationTemplate interactionType={InteractionType.Redirect} authenticationRequest={authRequest}>
                    <div>
                        <Header />
                        <Analytics />
                        {/*<Breadcrumbs/>*/}
                        {/*<LogoutUser />*/}
                        {/*<BrowserRouter>*/}
                        <HashRouter>
                            {/*<main>*/}
                            <Routes>
                                <Route path="/" element={<AdministratorTools />} />
                                <Route path="/courseAssignment/requests" element={<RequestsRouter />} />
                                <Route path="/courseAssignment/detail" element={<UserRequestDetails />} />
                                <Route path="/courseAssignment/create" element={<UploadFileRouter />} />
                                <Route path="/courseAssignment/enrollment" element={<EnrollmentTools />} />
                                <Route path="/courseAssignment/instructor" element={<InstructorToolsRouter />} />
                                <Route path="/courseAssignment/signInSheet" element={<SignInSheetComponent />} />
                                <Route path="/courseAssignment/courseMetrics" element={<SurveyMetrics />} />
                            </Routes>
                            {/*</main>*/}
                            {/*</BrowserRouter>*/}
                        </HashRouter>
                        <Footer />
                    </div>
                </MsalAuthenticationTemplate>
            );
        }
        else {
        return (<div id="root" className="alert"><h3>You are not setup with access to the CommScope University Utility application.<br />
            To request access, please contact  <a href="mailto: commscopeuniversity@commscope.com" className="text-primary">CommScopeUniversity@commscope.com</a>.</h3>
        </div>
        );
        }
    }
    else {
        const authRequestTemp = {
            scopes: [clientID, "offline_access", "openid", "profile"]
        };
        return (
            <MsalAuthenticationTemplate interactionType={InteractionType.Redirect} authenticationRequest={authRequestTemp}>
                <div>
                    <Header />
                    <Analytics />
                    {/*<Breadcrumbs/>*/}
                    {/*<LogoutUser />*/}
                    {/*<BrowserRouter>*/}
                    <HashRouter>
                        {/*<main>*/}
                        <Routes>
                            <Route path="/" element={<AdministratorTools />} />
                            <Route path="/courseAssignment/requests" element={<RequestsRouter />} />
                            <Route path="/courseAssignment/detail" element={<UserRequestDetails />} />
                            <Route path="/courseAssignment/create" element={<UploadFileRouter />} />
                            <Route path="/courseAssignment/enrollment" element={<EnrollmentTools />} />
                            <Route path="/courseAssignment/instructor" element={<InstructorToolsRouter />} />
                            <Route path="/courseAssignment/signInSheet" element={<SignInSheetComponent />} />
                            <Route path="/courseAssignment/courseMetrics" element={<SurveyMetrics />} />
                        </Routes>
                        {/*</main>*/}
                        {/*</BrowserRouter>*/}
                    </HashRouter>
                    <Footer />
                </div>
            </MsalAuthenticationTemplate>
        );
    }
/*    }, [loginroles]);*/
    
};


    const App = ({ instance }: { instance: any }) => {
        return (
            <MsalProvider instance={instance}>
                <Pages />
            </MsalProvider>
        );
    };
export default App;


