import React from 'react'
import { GridComponent, ColumnDirective, ColumnsDirective, Page, Inject, EditSettingsModel } from '@syncfusion/ej2-react-grids';
/*import { Button } from 'react-bootstrap';*/
import { useState } from 'react';
import UploadFile from '../UploadFile/UploadFile';
//import './Requests.css';
import UserRequestDetails from '../UserRequestDetails/UserRequestDetails';
import { ButtonComponent } from '@syncfusion/ej2-react-buttons';
import { withAuth } from 'react-oidc-context';
import { useAuth } from "react-oidc-context";
import configData from "../../../src/config.json";
import ManageToken from '../../Token';
import { Link, useNavigate } from 'react-router-dom';
import Breadcrumbs from '../Breadcrumbs/Breadcrumbs';
import LogoutUser from '../Logout/Logout';


class Requests extends React.Component<any, any> {
    isAdmin = false;
    grid: any;
    token: any;
    rowSelected() {
        if (this.grid) {
            console.log("inRowSelected")
            const selectedrecords = this.grid.getSelectedRecords();
            //this.setState({ requestId: selectedrecords[0].requestID },
            //    () => { console.log(this.state.requestId) });
            console.log("selectedRecords - " + selectedrecords)
            this.props.navigation("/courseAssignment/detail/?requestId=" + selectedrecords[0].requestID, { state: { requestId: selectedrecords[0].requestID }, replace: true });
        }
    }
    deleteRequestDetails(requestId: Int32Array, selectedRow: number) {
        this.setState({
            deleteSpinnerHidden: false,
        })
        var deleteRequestURL = process.env.REACT_APP_SERVER_URL_DELETE_REQUEST ? process.env.REACT_APP_SERVER_URL_DELETE_REQUEST.toString() : "";
        const requestOptions = {
            method: "POST",
            headers: {
                "Authorization": "Bearer " + this.token.GetToken()
            }
        };
        const deleteData = async () =>

            await fetch(deleteRequestURL + requestId, requestOptions)
                .then(res => res.json())
                .then(
                    (result) => {
                        this.setState({
                            deletedData: result,
                        }, () => {
                            console.log(this.state.deletedData)
                        });
                        if (selectedRow >= 0 && selectedRow != undefined) {
                            var tempArr = [...this.state.requestDetails]
                            tempArr.splice(selectedRow, 1)
                            this.setState({ requestDetails: tempArr })
                        }
                        this.setState({
                            deleteSpinnerHidden: true,
                        })
                    },
                    (error) => {
                        alert("API server error to delete data!");
                        this.setState({
                            deleteSpinnerHidden: true,
                        })
                    }
                )

        deleteData();
    }


    RefreshComponent = () => {
        window.location.reload();
    }
    public statusColTemplate: any = this.statusColumnTemplate;
    public statusColumnTemplate(props: any): any {
        return (
            <div className="row">

                <ButtonComponent cssClass='btn btn-primary btn btn-primary btn-sm'
                    onClick={(e: any) => this.submitRequest(e)}>Delete</ButtonComponent>

            </div>
        )
    }




    constructor(props: any) {

        super(props);
        this.token = new ManageToken();
        this.state = {
            requestDetails: [],
            deletedData: [],
            dataDisplaySpinnerHidden: true,
            deleteSpinnerHidden: true

        }

    }

    async componentDidMount() {
        this.setState({
            dataDisplaySpinnerHidden: false,
        })
        this.getRequestDetails();
    }

    submitRequest(e: any) {
        var selectedRow = 0;
        if (e.currentTarget.offsetParent?.parentElement?.ariaRowIndex != undefined) {
            selectedRow = parseInt(e.currentTarget.offsetParent?.parentElement?.ariaRowIndex);
        }
        console.log("inSubmitRequest - " + this.state.requestDetails)
        console.log("selectRow - " + selectedRow)
        var seletcedVal = this.state.requestDetails[selectedRow-1];
        this.deleteRequestDetails(seletcedVal.requestID, selectedRow-1);
    }
    getRequestDetails() {
       
        //var getAllRequest = "https://localhost:7216/api/Requests/GetRequestInfo";
        var getAllRequest = process.env.REACT_APP_SERVER_URL_GETALL_REQUEST ? process.env.REACT_APP_SERVER_URL_GETALL_REQUEST : "";
        //var bearerToken = "Bearer " + this.props.user.access_token; 
        const getData = async () =>
            await fetch(getAllRequest, {
                headers: {
                    "Authorization": "Bearer " + this.token.GetToken()
                }
            }).then(res => res.json())
                .then(
                    (result) => {
                        this.setState({
                            requestDetails: result,
                        })
                        this.setState({
                            dataDisplaySpinnerHidden: true,
                        })
                    },
                    (error) => {
                        alert("API server error to load Request details data!");
                        this.setState({
                            dataDisplaySpinnerHidden: true,
                        })
                    }
                )
        getData();
    }



    public render() {
        this.rowSelected = this.rowSelected.bind(this);
        return (
            <div>
                <div className="loading-indicator" hidden={this.state.dataDisplaySpinnerHidden}>
                    <div className="spinner-border text-primary">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
                <div className="loading-indicator" hidden={this.state.deleteSpinnerHidden}>
                    <div className="spinner-border text-primary">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
                <Breadcrumbs route="/courseAssignment/requests" name="Course Enrollment" />
                <LogoutUser isAdmin={this.isAdmin} />
                <div className="container">
                    <div className="row">
                        <div className="col">
                            <h2>My Requests</h2>
                            <hr></hr>
                            <Link to="/courseAssignment/create">
                                <button className="btn btn-primary">Create a new Request</button></Link>&nbsp;&nbsp;
                            <button className="btn btn-secondary"
                                onClick={this.RefreshComponent}>Refresh
                            </button>

                            <br /><br />

                            <GridComponent dataSource={this.state.requestDetails} rowSelected={this.rowSelected} allowPaging={true} ref={g => this.grid = g} >
                                <ColumnsDirective>

                                    <ColumnDirective field='requestName' headerText="Request Name" headerTextAlign="Left" width='120' textAlign="Left" />
                                    <ColumnDirective field='status' headerText="Status" headerTextAlign="Left" width='120' textAlign="Left" />
                                    <ColumnDirective field='createdDate' headerText="Created Date" headerTextAlign="Left" width='120' textAlign="Left" />
                                    <ColumnDirective field='userEmail' headerText="User Email" headerTextAlign="Left" width='150' textAlign="Left" />
                                    <ColumnDirective headerText="" template={this.statusColTemplate.bind(this)} headerTextAlign="Left" width='120' textAlign="Left" />
                                </ColumnsDirective>
                                <Inject services={[Page]} />
                            </GridComponent>

                        </div>
                    </div>
                </div>
            </div>
        );

    }


}
export function RequestsRouter() {
    const navigate = useNavigate();
    return (<Requests navigation={navigate} />);
}
export default withAuth(Requests)

