import { useEffect, useRef, useState } from "react";
import { Chart } from "react-chartjs-2";
import Select from "react-select";
import ManageToken from "../../Token";
import './SurveyMetricsStyles.css';
import {
    Chart as ChartJS,
    LinearScale,
    CategoryScale,
    BarElement,
    PointElement,
    LineElement,
    Legend,
    Tooltip,
    LineController,
    BarController,
} from 'chart.js';
import Breadcrumbs from "../Breadcrumbs/Breadcrumbs";
import LogoutUser from "../Logout/Logout";
ChartJS.register(
    LinearScale,
    CategoryScale,
    BarElement,
    PointElement,
    LineElement,
    Legend,
    Tooltip,
    LineController,
    BarController
);
let apiCount = 0;
let insCount = 0;
function SurveyMetrics() {
    const isAdmin = false;
    const token = new ManageToken();
    const [selectedOption, setSelectedOption] = useState('')
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [toggleState, setToggleState] = useState(1);
    const toggleTab = (index: any) => {
        setToggleState(index);
    };
    const [instructorNames, setInstructorNames] = useState([{}])
    var tempInstructorNames = [{}];
    const [InstructorSelected,setInstructorSelected]=useState<any>()
    const [submitDataSpinnerHidden, setSubmitDataSpinnerHidden] = useState(true)
    const [instructorSpinnerHidden, setInstructorSpinnerHidden]=useState(true)
    const [ResponsesVILT, setResponsesVILT] = useState<any>(new Array<any>)
    const tempResponsesVILT: any = useRef()
    const [ResponsesILT, setResponsesILT] = useState<Array<any>>(new Array<any>)
    const tempResponsesILT: any = useRef()
    const [ResponsesELEARN, setResponsesELEARN] = useState<any>(new Array<any>)
    const tempResponsesELEARN: any = useRef()
    const [VILTResponse, setVILTResponse] = useState([{
        date: '', courseRating: 0, instructorRating: 0, recommendationRating: 0, surveyCount: 0, promotorsCount: 0, detractorsCount: 0, averageCourseRating: '', averageInstructorRating: '', npsScore: ''
    }])
    const [ILTResponse, setILTResponse] = useState([{
        date: '', courseRating: 0, instructorRating: 0, recommendationRating: 0, surveyCount: 0, promotorsCount: 0, detractorsCount: 0, averageCourseRating: 0, averageInstructorRating: 0, npsScore: 0
    }])

    useEffect(() => { getInstructorNames() }, [])
    useEffect(() =>
    {
        if (apiCount === 5)
        {
            setSubmitDataSpinnerHidden(true)
        }
        if (insCount == 2) {setInstructorSpinnerHidden(true) }
    }, [ResponsesELEARN,ResponsesILT,ResponsesVILT,VILTResponse,ILTResponse])

    const options = {
        plugins: {
            responsive: true,
            legend: {
                position: 'top' as const,
            },
            title: {
                display: true,
                text: 'Survey Responses',
            }
        },
        scales: {
            y: {
                type: 'linear' as const,
                display: true,
                position: 'left' as const
            },
            y1: {
                type: 'linear' as const,
                display: true,
                position: 'right' as const,
                grid: {
                    drawOnChartArea: false,
                }

            }
        }
    };
    const VILTdata = {
        labels: ResponsesVILT.map((data:any)=>data.lastDayDate),
        datasets: [
            {
                type: 'line' as const,
                label: 'Response Rate',
                backgroundColor: 'LightBlue',
                borderColor: 'LightBlue',
                data: ResponsesVILT.map((x:any)=>x.responseRate),
                yAxisID: 'y1'
            },
            {
                type: 'line' as const,
                label: 'Course Sat',
                data: ResponsesVILT.map((x:any)=>x.courseSat),
                backgroundColor: 'MediumSeaGreen',
                borderColor: 'violet',
                borderWidth: 2,
                yAxisID: 'y1'
            },
            {
                type: 'bar' as const,
                label: 'Enrollments',
                data: ResponsesVILT.map((x:any)=>x.enrollments),
                backgroundColor: 'DeepSkyBlue',
                borderColor: 'white',
                borderWidth: 2,
                barThickness: 30,
                yAxisID: 'y'
            },
            {
                type: 'bar' as const,
                label: 'Surveys',
                data: ResponsesVILT.map((x: any) => x.responseCount),
                backgroundColor: 'Navy',
                borderColor: 'white',
                borderWidth: 2,
                barThickness: 30,
                yAxisID: 'y'
            }

        ],
    };
    const ELEARNdata = {
        labels: ResponsesELEARN.map((data: any) => data.lastDayDate),
        datasets: [
            {
                type: 'line' as const,
                label: 'Response Rate',
                backgroundColor: 'LightBlue',
                borderColor: 'LightBlue',
                data: ResponsesELEARN.map((x:any)=>x.responseRate),
                yAxisID: 'y1'
            },
            {
                type: 'line' as const,
                label: 'Course Sat',
                data: ResponsesELEARN.map((x: any) => x.courseSat),
                backgroundColor: 'MediumSeaGreen',
                borderColor: 'violet',
                borderWidth: 2,
                yAxisID: 'y1'
            },
            {
                type: 'bar' as const,
                label: 'Enrollments',
                data: ResponsesELEARN.map((x: any) => x.enrollments),
                backgroundColor: 'DeepSkyBlue',
                borderColor: 'white',
                borderWidth: 2,
                barThickness: 30,
                yAxisID: 'y'
            },
            {
                type: 'bar' as const,
                label: 'Surveys',
                data: ResponsesELEARN.map((x: any) => x.responseCount),
                backgroundColor: 'Navy',
                borderColor: 'white',
                borderWidth: 2,
                barThickness: 30,
                yAxisID: 'y'
            }

        ],
    };
    const ILTdata = {
        labels: ResponsesILT.map((data: any) => data.lastDayDate),
        datasets: [
            {
                type: 'line' as const,
                label: 'Response Rate',
                backgroundColor: 'LightBlue',
                borderColor: 'LightBlue',
                data: ResponsesILT.map((x:any) => x.responseRate),
                yAxisID: 'y1'
            },
            {
                type: 'line' as const,
                label: 'Course Sat',
                data: ResponsesILT.map((x: any) => x.courseSat),
                backgroundColor: 'MediumSeaGreen',
                borderColor: 'violet',
                borderWidth: 2,
                yAxisID: 'y1'
            },
            {
                type: 'bar' as const,
                label: 'Enrollments',
                data: ResponsesILT.map((x: any) => x.enrollments),
                backgroundColor: 'DeepSkyBlue',
                borderColor: 'white',
                borderWidth: 2,
                barThickness: 30,
                yAxisID: 'y'
            },
            {
                type: 'bar' as const,
                label: 'Surveys',
                data: ResponsesILT.map((x: any) => x.responseCount),
                backgroundColor: 'Navy',
                borderColor: 'white',
                borderWidth: 2,
                barThickness: 30,
                yAxisID: 'y'
            }

        ],
    };

    function onValueChange(e: any) {
        setSelectedOption(e.target.value)
    }
    function onStartDateSelect(e: any) {
        setStartDate(e.target.value)
    }
    function onEndDateSelect(e: any) {
        setEndDate(e.target.value)
    }

    //submit click code
    function formSubmit() {
        setSubmitDataSpinnerHidden(false)
        setInstructorSelected(null)
        apiCount = 0;
        if (startDate !== '' && endDate !== '' && selectedOption !== '')
        {
                        
            if (selectedOption === 'Internal Employees')
            {
                getResponsesForVILTInternal();
                getResponsesForILTInternal();
                getResponsesForELEARNInternal();
                getILTCourseDataForInternal('all');
                getVILTCourseDataForInternal('all');
            }
            else if (selectedOption === 'External Employees')
            {
                getResponsesForVILTExternal();
                getResponsesForILTExternal();
                getResponsesForELEARNExternal();
                getILTCourseDataForExternal('all');
                getVILTCourseDataForExternal('all');
            }
            else if (selectedOption === 'All Registrants')
            {
                getResponsesForVILTAll();
                getResponsesForILTAll();
                getResponsesForELEARNAll();
                getILTCourseDataForAll('all');
                getVILTCourseDataForAll('all');

            }
            
        }
        else alert("Please select Start date, End Date and Employee type!")
    }

    function handleInstructorChange(e: any) {
        if (startDate !== '' && endDate !== '' && selectedOption !== '') {
            setInstructorSpinnerHidden(false)
            insCount = 0;
            setInstructorSelected(e)
            if (e.value !== null && e.value !== undefined) {
                if (selectedOption === 'Internal Employees') {
                    getILTCourseDataForInternal(e.value)
                    getVILTCourseDataForInternal(e.value)
                }
                else if (selectedOption === 'External Employees') {
                    getILTCourseDataForExternal(e.value);
                    getVILTCourseDataForExternal(e.value);
                }
                else if (selectedOption === 'All Registrants') {
                    getILTCourseDataForAll(e.value);
                    getVILTCourseDataForAll(e.value);
                }
            }
        }
        else { alert("Please select Start date, End Date, Employee type and click Submit!") }
    }

    //Fetch Instructor names api
    function getInstructorNames() {
        var getInstructorNamesUrl = process.env.REACT_APP_SERVER_URL_GET_INSTRUCTORS_NAMES ? process.env.REACT_APP_SERVER_URL_GET_INSTRUCTORS_NAMES : "";
        const getData = async () =>
            await fetch(getInstructorNamesUrl, {
                headers: {
                    "Authorization": "Bearer " + token.GetToken()
                }
            })
                .then(res => res.json())
                .then(
                    (result) => {
                        /*var tempInstructorNames = ['']*/
                        for (let i = 0; i < result.length; i++) {
                            tempInstructorNames.push({ value: result[i], label: result[i] })
                        }
                        tempInstructorNames.shift();
                        setInstructorNames(tempInstructorNames);
                    },

                    (error) => {
                        alert("API server error to load instructor Names data!");
                    }
                )
        if (token.GetToken() !== undefined || token.GetToken() !== null || token.GetToken() !== "") {
            getData();
        }
    }

    //Internal Employee api calls
    function getResponsesForVILTInternal() {
        var getResponsesUrl = process.env.REACT_APP_SERVER_URL_GET_RESPONSES_INTERNAL ? process.env.REACT_APP_SERVER_URL_GET_RESPONSES_INTERNAL : "";
        const getResponseData = async () =>
            await fetch(getResponsesUrl + "VILT" + "&startDate=" + startDate + "&endDate=" + endDate, {
                headers: {
                    "Authorization": "Bearer " + token.GetToken()
                }
            })
                .then(res => res.json())
                .then(
                    (result) => {
                        tempResponsesVILT.current = new Array<any>
                        for (let i = 0; i < result.length; i++) {
                            tempResponsesVILT.current.push(result[i]);
                        }
                        apiCount = apiCount + 1;
                        setResponsesVILT(tempResponsesVILT.current)
                    },

                    (error) => {
                        alert("API server error to load data!");
                    }
                )
        if (token.GetToken() !== undefined || token.GetToken() !== null || token.GetToken() !== "") {
            getResponseData();
        }
    }
    function getResponsesForILTInternal() {
        var getResponsesUrl = process.env.REACT_APP_SERVER_URL_GET_RESPONSES_INTERNAL ? process.env.REACT_APP_SERVER_URL_GET_RESPONSES_INTERNAL : "";
        const getResponseData = async () =>
            await fetch(getResponsesUrl + "ILT" + "&startDate=" + startDate + "&endDate=" + endDate, {
                headers: {
                    "Authorization": "Bearer " + token.GetToken()
                }
            })
                .then(res => res.json())
                .then(
                    (result) => {
                        tempResponsesILT.current = new Array<any>
                        for (let i = 0; i < result.length; i++) {
                            tempResponsesILT.current.push(result[i]);
                        }
                        apiCount = apiCount + 1;
                        setResponsesILT(tempResponsesILT.current)
                    },

                    (error) => {
                        alert("API server error to load data!");
                    }
                )
        if (token.GetToken() !== undefined || token.GetToken() !== null || token.GetToken() !== "") {
            getResponseData();
        }
    }
    function getResponsesForELEARNInternal() {
        var getResponsesUrl = process.env.REACT_APP_SERVER_URL_GET_RESPONSES_INTERNAL ? process.env.REACT_APP_SERVER_URL_GET_RESPONSES_INTERNAL : "";
        const getResponseData = async () =>
            await fetch(getResponsesUrl + "eLearn" + "&startDate=" + startDate + "&endDate=" + endDate, {
                headers: {
                    "Authorization": "Bearer " + token.GetToken()
                }
            })
                .then(res => res.json())
                .then(
                    (result) => {
                        tempResponsesELEARN.current = new Array<any>
                        for (let i = 0; i < result.length; i++) {
                            tempResponsesELEARN.current.push(result[i]);
                        }
                        apiCount = apiCount + 1;
                        setResponsesELEARN(tempResponsesELEARN.current)
                    },

                    (error) => {
                        alert("API server error to load data!");
                    }
                )
        if (token.GetToken() !== undefined || token.GetToken() !== null || token.GetToken() !== "") {
            getResponseData();
        }
    }

    function getILTCourseDataForInternal(instructorName: string) {
        var getILTResponseUrl = process.env.REACT_APP_SERVER_URL_GET_COURSE_TYPE_DATA_INTERNAL ? process.env.REACT_APP_SERVER_URL_GET_COURSE_TYPE_DATA_INTERNAL : "";
        const getData = async () =>
            await fetch(getILTResponseUrl + "ILT" + "&startDate=" + startDate + "&endDate=" + endDate + "&instructorName=" + instructorName, {
                headers: {
                    "Authorization": "Bearer " + token.GetToken()
                }
            })
                .then(res => res.json())
                .then(
                    (result) => {
                        apiCount = apiCount + 1;
                        insCount = insCount + 1;
                        setILTResponse(result)
                        //if (apiCount == 2) {setInstructorSpinnerHidden(true) }
                    },

                    (error) => {
                        alert("API server error to load data!");
                    }
                )
        if (token.GetToken() !== undefined || token.GetToken() !== null || token.GetToken() !== "") {
            getData();
        }
    }
    function getVILTCourseDataForInternal(instructorName: string) {
        var getVILTResponseUrl = process.env.REACT_APP_SERVER_URL_GET_COURSE_TYPE_DATA_INTERNAL ? process.env.REACT_APP_SERVER_URL_GET_COURSE_TYPE_DATA_INTERNAL : "";
        const getData = async () =>
            await fetch(getVILTResponseUrl + "VILT" + "&startDate=" + startDate + "&endDate=" + endDate + "&instructorName=" + instructorName, {
                headers: {
                    "Authorization": "Bearer " + token.GetToken()
                }
            })
                .then(res => res.json())
                .then(
                    (result) => {
                        apiCount = apiCount + 1;
                        insCount = insCount + 1;
                        setVILTResponse(result)
                    },

                    (error) => {
                        alert("API server error to load data!");
                    }
                )
        if (token.GetToken() !== undefined || token.GetToken() !== null || token.GetToken() !== "") {
            getData();
        }
    }

    //External Employee api calls
    function getResponsesForVILTExternal() {
        var getResponsesUrl = process.env.REACT_APP_SERVER_URL_GET_RESPONSES_EXTERNAL ? process.env.REACT_APP_SERVER_URL_GET_RESPONSES_EXTERNAL : "";
        const getResponseData = async () =>
            await fetch(getResponsesUrl + "VILT" + "&startDate=" + startDate + "&endDate=" + endDate, {
                headers: {
                    "Authorization": "Bearer " + token.GetToken()
                }
            })
                .then(res => res.json())
                .then(
                    (result) => {
                        tempResponsesVILT.current = new Array<any>
                        for (let i = 0; i < result.length; i++) {
                            tempResponsesVILT.current.push(result[i]);
                        }
                        apiCount = apiCount + 1;
                        setResponsesVILT(tempResponsesVILT.current)
                    },

                    (error) => {
                        alert("API server error to load data!");
                    }
                )
        if (token.GetToken() !== undefined || token.GetToken() !== null || token.GetToken() !== "") {
            getResponseData();
        }
    }
    function getResponsesForILTExternal() {
        var getResponsesUrl = process.env.REACT_APP_SERVER_URL_GET_RESPONSES_EXTERNAL ? process.env.REACT_APP_SERVER_URL_GET_RESPONSES_EXTERNAL : "";
        const getResponseData = async () =>
            await fetch(getResponsesUrl + "ILT" + "&startDate=" + startDate + "&endDate=" + endDate, {
                headers: {
                    "Authorization": "Bearer " + token.GetToken()
                }
            })
                .then(res => res.json())
                .then(
                    (result) => {
                        tempResponsesILT.current = new Array<any>
                        for (let i = 0; i < result.length; i++) {
                            tempResponsesILT.current.push(result[i]);
                        }
                        apiCount = apiCount + 1;
                        setResponsesILT(tempResponsesILT.current)
                    },

                    (error) => {
                        alert("API server error to load data!");
                    }
                )
        if (token.GetToken() !== undefined || token.GetToken() !== null || token.GetToken() !== "") {
            getResponseData();
        }
    }
    function getResponsesForELEARNExternal() {
        var getResponsesUrl = process.env.REACT_APP_SERVER_URL_GET_RESPONSES_EXTERNAL ? process.env.REACT_APP_SERVER_URL_GET_RESPONSES_EXTERNAL : "";
        const getResponseData = async () =>
            await fetch(getResponsesUrl + "eLearn" + "&startDate=" + startDate + "&endDate=" + endDate, {
                headers: {
                    "Authorization": "Bearer " + token.GetToken()
                }
            })
                .then(res => res.json())
                .then(
                    (result) => {
                        tempResponsesELEARN.current = new Array<any>
                        for (let i = 0; i < result.length; i++) {
                            tempResponsesELEARN.current.push(result[i]);
                        }
                        apiCount = apiCount + 1;
                        setResponsesELEARN(tempResponsesELEARN.current)
                    },

                    (error) => {
                        alert("API server error to load data!");
                    }
                )
        if (token.GetToken() !== undefined || token.GetToken() !== null || token.GetToken() !== "") {
            getResponseData();
        }
    }
    function getILTCourseDataForExternal(instructorName: string) {
        var getILTResponseUrl = process.env.REACT_APP_SERVER_URL_GET_COURSE_TYPE_DATA_EXTERNAL ? process.env.REACT_APP_SERVER_URL_GET_COURSE_TYPE_DATA_EXTERNAL : "";
        const getData = async () =>
            await fetch(getILTResponseUrl + "ILT" + "&startDate=" + startDate + "&endDate=" + endDate + "&instructorName=" + instructorName, {
                headers: {
                    "Authorization": "Bearer " + token.GetToken()
                }
            })
                .then(res => res.json())
                .then(
                    (result) => {
                        apiCount = apiCount + 1;
                        insCount = insCount + 1;
                        setILTResponse(result)
                    },

                    (error) => {
                        alert("API server error to load data!");
                    }
                )
        if (token.GetToken() !== undefined || token.GetToken() !== null || token.GetToken() !== "") {
            getData();
        }
    }
    function getVILTCourseDataForExternal(instructorName: string) {
        var getVILTResponseUrl = process.env.REACT_APP_SERVER_URL_GET_COURSE_TYPE_DATA_EXTERNAL ? process.env.REACT_APP_SERVER_URL_GET_COURSE_TYPE_DATA_EXTERNAL : "";
        const getData = async () =>
            await fetch(getVILTResponseUrl + "VILT" + "&startDate=" + startDate + "&endDate=" + endDate + "&instructorName=" + instructorName, {
                headers: {
                    "Authorization": "Bearer " + token.GetToken()
                }
            })
                .then(res => res.json())
                .then(
                    (result) => {
                        apiCount = apiCount + 1;
                        insCount = insCount + 1;
                        setVILTResponse(result)
                    },

                    (error) => {
                        alert("API server error to load data!");
                    }
                )
        if (token.GetToken() !== undefined || token.GetToken() !== null || token.GetToken() !== "") {
            getData();
        }
    }

    //All registrants api calls
    function getResponsesForVILTAll() {
        var getResponsesUrl = process.env.REACT_APP_SERVER_URL_GET_RESPONSES_ALL ? process.env.REACT_APP_SERVER_URL_GET_RESPONSES_ALL : "";
        const getResponseData = async () =>
            await fetch(getResponsesUrl + "VILT" + "&startDate=" + startDate + "&endDate=" + endDate, {
                headers: {
                    "Authorization": "Bearer " + token.GetToken()
                }
            })
                .then(res => res.json())
                .then(
                    (result) => {
                        tempResponsesVILT.current = new Array<any>
                        for (let i = 0; i < result.length; i++) {
                            tempResponsesVILT.current.push(result[i]);
                        }
                        apiCount = apiCount + 1;
                        setResponsesVILT(tempResponsesVILT.current)
                    },

                    (error) => {
                        alert("API server error to load data!");
                    }
                )
        if (token.GetToken() !== undefined || token.GetToken() !== null || token.GetToken() !== "") {
            getResponseData();
        }
    }
    function getResponsesForILTAll() {
        var getResponsesUrl = process.env.REACT_APP_SERVER_URL_GET_RESPONSES_ALL ? process.env.REACT_APP_SERVER_URL_GET_RESPONSES_ALL : "";
        const getResponseData = async () =>
            await fetch(getResponsesUrl + "ILT" + "&startDate=" + startDate + "&endDate=" + endDate, {
                headers: {
                    "Authorization": "Bearer " + token.GetToken()
                }
            })
                .then(res => res.json())
                .then(
                    (result) => {
                        tempResponsesILT.current = new Array<any>
                        for (let i = 0; i < result.length; i++) {
                            tempResponsesILT.current.push(result[i]);
                        }
                        apiCount = apiCount + 1;
                        setResponsesILT(tempResponsesILT.current)
                    },

                    (error) => {
                        alert("API server error to load data!");
                    }
                )
        if (token.GetToken() !== undefined || token.GetToken() !== null || token.GetToken() !== "") {
            getResponseData();
        }
    }
    function getResponsesForELEARNAll() {
        var getResponsesUrl = process.env.REACT_APP_SERVER_URL_GET_RESPONSES_ALL ? process.env.REACT_APP_SERVER_URL_GET_RESPONSES_ALL : "";
        const getResponseData = async () =>
            await fetch(getResponsesUrl + "eLearn" + "&startDate=" + startDate + "&endDate=" + endDate, {
                headers: {
                    "Authorization": "Bearer " + token.GetToken()
                }
            })
                .then(res => res.json())
                .then(
                    (result) => {
                        tempResponsesELEARN.current = new Array<any>
                        for (let i = 0; i < result.length; i++) {
                            tempResponsesELEARN.current.push(result[i]);
                        }
                        apiCount = apiCount + 1;
                        setResponsesELEARN(tempResponsesELEARN.current)
                    },

                    (error) => {
                        alert("API server error to load data!");
                    }
                )
        if (token.GetToken() !== undefined || token.GetToken() !== null || token.GetToken() !== "") {
            getResponseData();
        }
    }
    function getILTCourseDataForAll(instructorName: string) {
        var getILTResponseUrl = process.env.REACT_APP_SERVER_URL_GET_COURSE_TYPE_DATA_ALL ? process.env.REACT_APP_SERVER_URL_GET_COURSE_TYPE_DATA_ALL : "";
        const getData = async () =>
            await fetch(getILTResponseUrl + "ILT" + "&startDate=" + startDate + "&endDate=" + endDate + "&instructorName=" + instructorName, {
                headers: {
                    "Authorization": "Bearer " + token.GetToken()
                }
            })
                .then(res => res.json())
                .then(
                    (result) => {
                        apiCount = apiCount + 1;
                        insCount = insCount + 1;
                        setILTResponse(result)
                    },

                    (error) => {
                        alert("API server error to load data!");
                    }
                )
        if (token.GetToken() !== undefined || token.GetToken() !== null || token.GetToken() !== "") {
            getData();
        }
    }
    function getVILTCourseDataForAll(instructorName: string) {
        var getVILTResponseUrl = process.env.REACT_APP_SERVER_URL_GET_COURSE_TYPE_DATA_ALL ? process.env.REACT_APP_SERVER_URL_GET_COURSE_TYPE_DATA_ALL : "";
        const getData = async () =>
            await fetch(getVILTResponseUrl + "VILT" + "&startDate=" + startDate + "&endDate=" + endDate + "&instructorName=" + instructorName, {
                headers: {
                    "Authorization": "Bearer " + token.GetToken()
                }
            })
                .then(res => res.json())
                .then(
                    (result) => {
                        apiCount = apiCount + 1;
                        insCount = insCount + 1;
                        setVILTResponse(result)
                    },

                    (error) => {
                        alert("API server error to load data!");
                    }
                )
        if (token.GetToken() !== undefined || token.GetToken() !== null || token.GetToken() !== "") {
            getData();
        }
    }

    function RefreshComponent() {
        window.location.reload();
    }

  

    return (
        <div>
            <div className="loading-indicator" hidden={submitDataSpinnerHidden}>
                <div className="spinner-border text-primary">
                    <span className="sr-only">Loading...</span>
                </div>
            </div>
            <div className="loading-indicator" hidden={instructorSpinnerHidden}>
                <div className="spinner-border text-primary">
                    <span className="sr-only">Loading...</span>
                </div>
            </div>
            <Breadcrumbs route="/courseAssignment/surveyMetrics" name="Survey Metrics" />
            <LogoutUser isAdmin={isAdmin} />
            <div className="container">
                <div>
                        <div className="calender">
                            <label>Start Date</label>
                            <div><input type="date" style={{ width: '80%' }} onChange={onStartDateSelect} value={startDate} /></div>
                            <label>End Date</label>
                            <div><input type="date" style={{ width: '80%' }} onChange={onEndDateSelect} value={endDate} /></div>
                        </div><br/>
                        <strong>Employee type:</strong>
                        <div className="radio">
                            <label>
                                <input
                                    type="radio"
                                    value="Internal Employees"
                                    checked={selectedOption === "Internal Employees"}
                                    onChange={onValueChange}
                                />
                                Internal Employees
                            </label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

                            <label>
                                <input
                                    type="radio"
                                    value="External Employees"
                                    checked={selectedOption === "External Employees"}
                                    onChange={onValueChange}
                                />
                                External Employees
                            </label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

                            <label>
                                <input
                                    type="radio"
                                    value="All Registrants"
                                    checked={selectedOption === "All Registrants"}
                                    onChange={onValueChange}
                                />
                                All Registrants
                            </label>
                        </div><br/>
                        <div>Please select Start Date, End Date and Employee Type before clicking on Submit button</div>
                        <div className="buttonContainer">
                            <button className="btn btn-primary" onClick={formSubmit}>Submit</button>&nbsp;&nbsp;
                            <button className="btn btn-secondary" onClick={RefreshComponent}>Reset</button>
                        </div>
                  
                </div>

                <div>
                    <div className="bloc-tabs">
                        <button className={toggleState === 1 ? "tabs active-tabs" : "tabs"}
                            onClick={() => toggleTab(1)}>
                            Graphs
                        </button>
                        <button className={toggleState === 2 ? "tabs active-tabs" : "tabs"}
                            onClick={() => toggleTab(2)} >
                            Instructors
                        </button>
                    </div>
                    <div className="content-tabs">
                        <div className={toggleState === 1 ? "contentTab  active-content" : "contentTab"}>
                            <div><b>VILT</b></div>
                            <Chart type='bar' options={options} data={VILTdata} />
                            <div><b>ILT</b></div>
                            <Chart type='bar' options={options} data={ILTdata} />
                            <div><b>ELEARN</b></div>
                            <Chart type='bar' options={options} data={ELEARNdata} />
                        </div>

                        <div className={toggleState === 2 ? "contentTab  active-content" : "contentTab"}>
                            <div id="instructorsContainer">
                                <label>Select an Instructor:
                                    <Select placeholder="-- Select an Instructor --" options={instructorNames} noOptionsMessage={() => 'No options Found'} onChange={handleInstructorChange} value={InstructorSelected}  />
                                </label>
                            </div>
                            <div id="ILTtableContainer">
                                <div className="tableHeader">ILT</div>
                                <table className="table table-striped">
                                    <thead>
                                        <tr>
                                            <th>Date</th>
                                            <th>Course Rating</th>
                                            <th>Instructor Rating</th>
                                            <th>NPS Score</th>
                                            <th>Survey Count</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {ILTResponse.map((response, index) =>
                                            <tr>
                                                <td>{response.date}</td>
                                                <td>{response.courseRating}</td>
                                                <td>{response.instructorRating}</td>
                                                <td>{response.npsScore}</td>
                                                <td>{response.surveyCount}</td>
                                            </tr>)}
                                    </tbody>
                                </table><br />
                                <br />
                            </div>
                            <div id="VILTtableContainer">
                                <div className="tableHeader">VILT</div>
                                <table className="table table-striped">
                                    <thead>
                                        <tr>
                                            <th>Date</th>
                                            <th>Course Rating</th>
                                            <th>Instructor Rating</th>
                                            <th>NPS Score</th>
                                            <th>Survey Count</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {VILTResponse.map((user, index) =>
                                            <tr>
                                                <td>{user.date}</td>
                                                <td>{user.averageCourseRating}</td>
                                                <td>{user.averageInstructorRating}</td>
                                                <td>{user.npsScore}</td>
                                                <td>{user.surveyCount}</td>
                                            </tr>)}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>);
}
export default SurveyMetrics;