import React, { useEffect, useState } from 'react'
import { GridComponent, ColumnDirective, ColumnsDirective, Page, Inject } from '@syncfusion/ej2-react-grids';
import ManageToken from '../../Token';
import { useLocation } from 'react-router-dom';
import Breadcrumbs from '../Breadcrumbs/Breadcrumbs';
import LogoutUser from '../Logout/Logout';

function UserRequestDetails(props: any) {
    const isAdmin = false;
    const token = new ManageToken();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    let requestId = searchParams.get("requestId")?.toString();
    let num = 0;
    const [requestItemDetails, setRequestItemDetails] = useState([])
    const [requestDetails, setRequestDetails] = useState([{
        "requestID": 0,
        "status": '',
        "createdDate": '',
        "userEmail": '',
        "requestName": ''
    }])
    const [dataDisplaySpinnerHidden, setdataDisplaySpinnerHidden] = useState(true)   


    function getRequestItemDetails() {
        setdataDisplaySpinnerHidden(false)
        //var getItemDetails = "https://localhost:7216/api/Requests/GetRequestItemDetails/";
        var getItemDetails = process.env.REACT_APP_SERVER_URL_GET_REQUESTITEMDETAILS ? process.env.REACT_APP_SERVER_URL_GET_REQUESTITEMDETAILS : "";
        const getItemData = async () =>
            await fetch(getItemDetails + requestId, {
                headers: {
                    "Authorization": "Bearer " + token.GetToken()
                }
            })
                .then(res => res.json())
                .then(
                    (result) => {
                        setRequestItemDetails(result)
                        num = num + 1;
                        if (num == 2) {
                            setdataDisplaySpinnerHidden(true)
                        }
                    },
                    (error) => {
                        alert("API server error to load data!");
                        setdataDisplaySpinnerHidden(true)
                    }
                )
        getItemData();
    }

    function getRequestDetails() {
        setdataDisplaySpinnerHidden(false)
        //var getRequest = "https://localhost:7216/api/Requests/GetRequestDetails/";
        var getRequest = process.env.REACT_APP_SERVER_URL_GET_REQUESTDETAILS ? process.env.REACT_APP_SERVER_URL_GET_REQUESTDETAILS : "";
        const getData = async () =>
            await fetch(getRequest + requestId, {
                headers: {
                    "Authorization": "Bearer " + token.GetToken()
                }
            })
                .then(res => res.json())
                .then(
                    (result) => {
                        setRequestDetails(result)
                        num = num + 1;
                        if (num == 2) {
                            setdataDisplaySpinnerHidden(true)
                        }
                    },
                    (error) => {
                        alert("API server error to load data!");
                        setdataDisplaySpinnerHidden(true)
                    }
                )
        getData();
    }
    useEffect(() => {
        getRequestItemDetails();
        getRequestDetails();
    }, [])
    return (
        <div>
            <div className="loading-indicator" hidden={dataDisplaySpinnerHidden}>
                <div className="spinner-border text-primary">
                    <span className="sr-only">Loading...</span>
                </div>
            </div>
            <Breadcrumbs route="/courseAssignment/requests" name="Course Enrollment" />
            <LogoutUser isAdmin={isAdmin} />
            <div className="container">
                <div className="row">
                    <div className="col-sm-12">
                        <h2>Request Details</h2>
                        <hr></hr>

                        <div className="row">
                            <div className="col-md-auto">
                                <b>Request Name:</b> {requestDetails[0].requestName}
                            </div>
                            <div className="col col-xl-2 col-lg-2">
                                <b> Status:</b> {requestDetails[0].status}
                            </div>
                        </div><br />
                        <div className="row">
                            <div className="col-sm">
                                <b> Created Date: </b>&nbsp; {requestDetails[0].createdDate}
                            </div>

                        </div>
                        <br />
                        {/*<div className="row">*/}

                        <GridComponent dataSource={requestItemDetails} allowPaging={true}>
                            <ColumnsDirective>
                                <ColumnDirective field='firstName' headerText="First Name" headerTextAlign="Left" width='120' textAlign="Left" />
                                <ColumnDirective field='lastName' headerText="Last Name" headerTextAlign="Left" width='120' textAlign="Left" />
                                <ColumnDirective field='email' headerText="Email ID" headerTextAlign="Left" width='120' textAlign="Left" />
                                <ColumnDirective field='courseID' headerText="Course ID" headerTextAlign="Left" width='200' textAlign="Left" />
                            </ColumnsDirective>
                            <Inject services={[Page]} />
                        </GridComponent>

                        {/*</div>*/}
                    </div>

                </div>

            </div>

        </div>
    );


}
export default UserRequestDetails;