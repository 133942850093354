import * as React from 'react';

const Footer = () => {
    const [footer, setFooter] = React.useState("");
    const [display, setDisplay] = React.useState(true);

    React.useEffect(() => {
        DownloadFooter();
    }, []);

    const DownloadFooter = React.useCallback(() => {
        const cachedFooter = localStorage.getItem('commscopeFooter');
        const footerUrl = process.env.REACT_APP_SERVER_URL_FOOTER ? process.env.REACT_APP_SERVER_URL_FOOTER.toString() : ""

        const cacheExpiration = 4 * 60 * 60 * 1000; // 4 hours in milliseconds

        if (cachedFooter) {
            const cachedTime = localStorage.getItem('commscopeFooterExpiry');
            if (cachedTime && Date.now() - parseInt(cachedTime) < cacheExpiration) {
                var temp = cachedFooter;
                setFooter(temp);
            } else {
                if (footerUrl) {
                    fetch(footerUrl)
                        .then((res) => res.text())
                        .then((result) => {
                            var temp = result;
                            setFooter('');
                            setFooter(temp);
                            localStorage.setItem('commscopeFooter', temp);
                            localStorage.setItem('commscopeFooterExpiry', Date.now().toString());
                        }, (error) => {
                            // alert('err' + error.data);
                        });
                }
                else {
                    //This is just an error handling. This should never happen.
                    fetch('https://extapps.commscope.com/footer.aspx')
                        .then((res) => res.text())
                        .then((result) => {
                            var temp = result;
                            setFooter('');
                            setFooter(temp);
                            localStorage.setItem('commscopeFooter', temp);
                            localStorage.setItem('commscopeFooterExpiry', Date.now().toString());
                        }, (error) => {
                            // alert('err' + error.data);
                        });
                }
            }
        } else {
            if (footerUrl) {
                fetch(footerUrl)
                    .then((res) => res.text())
                    .then((result) => {
                        setFooter('');
                        setFooter(result);
                        localStorage.setItem('commscopeFooter', result);
                        localStorage.setItem('commscopeFooterExpiry', Date.now().toString());
                    }, (error) => {
                        // alert('err' + error.data);
                    });
            }
        }
    }, []);


    React.useEffect(() => {
        setTimeout(() => { setDisplay(false) }, 1000);
    }, []);

    return (
        <div className="content" dangerouslySetInnerHTML={{ __html: footer }} hidden={display}></div>
    );
};

export default Footer;
