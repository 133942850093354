import { Console } from 'console';
import * as React from 'react';

function Header() {
    const [header, setHeader] = React.useState('');
    const [display, setDisplay] = React.useState(true);
    const [test, setTest] = React.useState('');

    const DownloadHeader = React.useCallback(() => {
        const cachedHeader = localStorage.getItem('commscopeHeader');
        const cacheExpiration = 4 * 60 * 60 * 1000; // 4 hours in milliseconds
        const cachedTime = localStorage.getItem('commscopeHeaderExpiry');
        const headerUrl = process.env.REACT_APP_SERVER_URL_HEADER ? process.env.REACT_APP_SERVER_URL_HEADER.toString() : ""

        if (cachedHeader) {


            if (cachedTime && Date.now() - parseInt(cachedTime) < cacheExpiration) {
                var temp = cachedHeader;
                setHeader(temp);
            } else {
                if (headerUrl) {
                    fetch(headerUrl)
                        .then((res) => res.text())
                        .then((result) => {
                            var temp = result;
                            setHeader('');
                            setHeader(temp);
                            localStorage.setItem('commscopeHeader', temp);
                            localStorage.setItem('commscopeHeaderExpiry', Date.now().toString());
                        }, (error) => {
                            // alert('err' + error.data);
                        });
                }
                else {
                    //This is just an error handling. This should never happen.
                    //This is worst case scenario. If the header url is not found in the app config, then we will use the default header url.
                    fetch("https://extapps.commscope.com/header.aspx")
                        .then((res) => res.text())
                        .then((result) => {
                            var temp = result;
                            setHeader('');
                            setHeader(temp);
                            localStorage.setItem('commscopeHeader', temp);
                            localStorage.setItem('commscopeHeaderExpiry', Date.now().toString());
                        }, (error) => {
                            // alert('err' + error.data);
                        });
                }
            }
        } else {
            if (headerUrl) {
                fetch(headerUrl)
                    .then((res) => res.text())
                    .then((result) => {
                        setHeader('');
                        setHeader(result);
                        localStorage.setItem('commscopeHeader', result);
                        localStorage.setItem('commscopeHeaderExpiry', Date.now().toString());
                    }, (error) => {
                        // alert('err' + error.data);
                    });
            }
        }
    }, []);

    React.useEffect(() => {
        DownloadHeader();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    React.useEffect(() => {
        setTimeout(() => { setDisplay(false) }, 1000);
    }, [display]);

    React.useEffect(() => {
        console.log(header)
    }, [Header])

    return (
        <div className="content" dangerouslySetInnerHTML={{ __html: header }} hidden={display}></div>
    );
};

export default Header;