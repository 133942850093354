import * as React from 'react';

const Analytics = () => {
    const [Analytics, setAnalytics] = React.useState("");
    const [display, setDisplay] = React.useState(true);

    React.useEffect(() => {
        DownloadAnalytics();
    }, []);

    const DownloadAnalytics = React.useCallback(() => {
        const cachedAnalytics = localStorage.getItem('commscopeAnalytics');
        const AnalyticsUrl = process.env.REACT_APP_SERVER_URL_ANALYTICS ? process.env.REACT_APP_SERVER_URL_ANALYTICS.toString() : ""

        const cacheExpiration = 4 * 60 * 60 * 1000; // 4 hours in milliseconds

        if (cachedAnalytics) {
            const cachedTime = localStorage.getItem('commscopeAnalyticsExpiry');
            if (cachedTime && Date.now() - parseInt(cachedTime) < cacheExpiration) {
                var temp = cachedAnalytics;
                setAnalytics(temp);
            } else {
                if (AnalyticsUrl) {
                    fetch(AnalyticsUrl)
                        .then((res) => res.text())
                        .then((result) => {
                            var temp = result;
                            setAnalytics('');
                            setAnalytics(temp);
                            localStorage.setItem('commscopeAnalytics', temp);
                            localStorage.setItem('commscopeAnalyticsExpiry', Date.now().toString());
                        }, (error) => {
                            // alert('err' + error.data);
                        });
                }
                else {
                    //This is just an error handling. This should never happen.
                    fetch('https://extapps.commscope.com/Analytics.aspx')
                        .then((res) => res.text())
                        .then((result) => {
                            var temp = result;
                            setAnalytics('');
                            setAnalytics(temp);
                            localStorage.setItem('commscopeAnalytics', temp);
                            localStorage.setItem('commscopeAnalyticsExpiry', Date.now().toString());
                        }, (error) => {
                            // alert('err' + error.data);
                        });
                }
            }
        } else {
            if (AnalyticsUrl) {
                fetch(AnalyticsUrl)
                    .then((res) => res.text())
                    .then((result) => {
                        setAnalytics('');
                        setAnalytics(result);
                        localStorage.setItem('commscopeAnalytics', result);
                        localStorage.setItem('commscopeAnalyticsExpiry', Date.now().toString());
                    }, (error) => {
                        // alert('err' + error.data);
                    });
            }
        }
    }, []);


    React.useEffect(() => {
        setTimeout(() => { setDisplay(false) }, 1000);
    }, []);

    return (
        <div className="content" dangerouslySetInnerHTML={{ __html: Analytics }} hidden={display}></div>
    );
};

export default Analytics;
