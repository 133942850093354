import React from 'react';
import { Link } from 'react-router-dom';
import Breadcrumbs from '../Breadcrumbs/Breadcrumbs';
import LogoutUser from '../Logout/Logout';

function AdministratorTools(props: any)
{
    const isAdmin = true;
    return(
        <div>
            <Breadcrumbs />
            <LogoutUser isAdmin />
            <div className="container">
                <div className="row">
                    <div className="col">
                        <div className="card card-bar-blue-dark">
                            <div className="card-header">
                                Course Enrollment
                            </div>
                            <div className="card-body">
                                <p className="card-text text-gray-400">Enroll new and existing users into a course.</p><br/>
                                <Link to="/courseAssignment/requests">
                                    <button className="btn btn-primary">Enroll Users</button></Link>
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="card card-bar-blue-dark">
                            <div className="card-header">
                                Session Enrollment
                            </div>
                            <div className="card-body">
                                <p className="card-text text-gray-400">Enroll customers into specific instructor-led course sessions.</p><br/>
                                <Link to="/courseAssignment/enrollment">
                                    <button className="btn btn-secondary">Enroll Customers</button></Link>
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="card card-bar-blue-dark">
                            <div className="card-header">
                                Instructor Tools
                            </div>
                            <div className="card-body">
                                <p className="card-text text-gray-400">Set up surveys and view replies. Download sign-in sheets and attendance.</p>
                                <Link to="/courseAssignment/instructor">
                                    <button className="btn btn-secondary" disabled>Assign Instructor</button></Link>
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="card card-bar-blue-dark">
                            <div className="card-header">
                                Survey Metrics
                            </div>
                            <div className="card-body">
                                <p className="card-text text-gray-400">Review survey results and view graphs/details by instructor.</p>
                                <Link to="/courseAssignment/courseMetrics">
                                    <button className="btn btn-secondary" disabled>Course Metrics</button></Link>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    );

}
export default AdministratorTools;