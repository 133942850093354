import React, {useState } from 'react'
import { Link } from 'react-router-dom';
import './BreadcrumbsStyles.css'

function Breadcrumbs(props: any)
{
    return (
        <div className="hero hero-subpages hero-blue-dark mb-4">
            <div className="hero-head">
                <div className="container">
                    <div className="row">
                        <div className="col-md-8">
                            <ol className="breadcrumb breadcrumb-inverse">
                                <li className="breadcrumb-item"><a href="http://www.commscope.com/membership/">My CommScope</a></li>
                                <li className="breadcrumb-item"><a href="/">CommScope University</a></li>
                                {/*{routes.map((route) => (*/}
                                {/*    <li className="breadcrumb-item"><a href={route.routeName}></a>{route.title}</li>*/}

                                {/*))}*/}
                                {props.name != null && <li className="breadcrumb-item"><Link to={props.route}>{props.name}</Link> </li>}
                            </ol>                          
                        </div>
                    </div>
                </div>
            </div>
            <div className="hero-footer">
                <div className="container">
                    <div className="hero-title">
                        <h1 className="display-3">
                            <span id="lblAppName" style={{ float: 'left' }}>CommScope University</span>
                        </h1>
                    </div>
                </div>
            </div>
        </div>
    );

}
export default Breadcrumbs;