import Breadcrumbs from "../Breadcrumbs/Breadcrumbs";
import LogoutUser from "../Logout/Logout";
import Select from "react-select";
import ManageToken from "../../Token";
import React,{ useEffect, useState } from "react";
import { setChecked } from "@syncfusion/ej2-react-grids";
import SignInSheetComponent from "../SignInSheet/SignInSheet";
import { Link, Route, useNavigate } from "react-router-dom";
import { withAuth } from "react-oidc-context";

function InstructorTools(props: any)
{
    const isAdmin= false;
    const token = new ManageToken();
    const [courseNames, setCourseNames] = useState([])
    const [selectedCourse, setSelectedCourse] = useState();
    const[selectedCourseName,setSelectedCourseName]=useState('')

    const [sessionNames, setSessionNames] = useState([])
    const [selectedCourseSession, setSelectedCourseSession] = useState(); 

    const [enrolledUsers, setEnrolledUsers] = useState([{
        "firstName": '',
        "lastName": '',
        "emailAddress": '',
        "company": ''
    }])
    const [selectedOption, setSelectedOption] = useState('')
    const [selectedCollectorType, setSelectedCollectorType] = useState('')  
    const [lineContainerHidden, setLineContainerHidden] = useState(true)
    const [signInTableContainerHidden, setSignInTableContainerHidden] = useState(true)
    const [surveyCollectorContainerHidden, setSurveyCollectorContainerHidden] = useState(true)

    const [surveyNames, setSurveyNames] = useState([])
    const [selectedSurvey, setSelectedSurvey] = useState();

    const [collectorNames, setCollectorNames] = useState([])
    const [selectedCollector, setSelectedCollector] = useState('')
    const [infoMessage, setInfoMessage]=useState('')
    const [collectorName, setCollectorName] = useState('')
    const [subjectName, setSubjectName] = useState('')
    const [bodyMessage, setBodyMessage] = useState('')
    const [checkedEmails, setCheckedEmails] = useState([''])    

    const [instructorId, setInstructorId]=useState([])
    const [sessionScheduleId, setSessionScheduleId] = useState([])
    const [isCourseLoading, setIsCourseLoading] = useState(false);
    const [isSessionLoading, setIsSessionLoading] = useState(false);
    const [enrollUsersSpinnerHidden, setenrollUsersSpinnerHidden] = useState(true)
    const [isCollectorLoading, setIsCollectorLoading] = useState(false)
    const [submitSpinnerHidden, setSubmitSpinnerHidden] = useState(true)

    useEffect(() => {
        getCourseDetails();
        getSurveyDetails();
    }, [])
       
    function handleCourseChange(event: any)
    {
        setSelectedCourseName(event.label)
        setSelectedCourse(event.value)
        if (event.value !== null && event.value !== undefined) {
            getSessionDetails(event.value);
        }
    }
    function handleSessionChange(event:any)
    {
        setenrollUsersSpinnerHidden(false)
        setSelectedCourseSession(event.value)
        if (event.value !== null && event.value !== undefined)
        {
            getEnrolledUsers(event.value)
        }
        

    }
    function handleSurveyChange(e:any)
    {
        setSelectedSurvey(e.value)
        if (e.value !== null && e.value !== undefined && selectedOption === "Use Exsisting")
        {
            getCollectorDetailsOnSurvey(e.value)
        }        
        
    }
    function handleCollectorChange(e:any)
    {
        setSelectedCollector(e.value)
    }
    function handleCollectorName(e:any)
    {
        setCollectorName(e.target.value)
    }
    function handleSubjectName(e: any)
    {
        setSubjectName(e.target.value)
    }
    function handleBodyMessage(e: any)
    {
        setBodyMessage(e.target.value)
    }
    function handleEmails(e:any)
    {
        if (e.target.checked)
        {
            setCheckedEmails((prev: any) => [...prev, e.target.value]);
        }
        else
        {
            const filteredEmails = checkedEmails.filter(elem => elem !== e.target.value)
            setCheckedEmails(filteredEmails)
        }
        
    }
    function handleSubmit()
    {
        setSubmitSpinnerHidden(false)
        if (selectedOption === "Use Exsisting" && selectedCollectorType === "Web Link Collector") {
            submitWebLinkCollector()
        }
        else if (selectedOption === "Use Exsisting" && selectedCollectorType === "Email Collector") {
            submitEmailCollector()
        }
        else if (selectedOption === "Make New" && selectedCollectorType === "Web Link Collector") {
            createWebLinkCollector()
        }
        else if (selectedOption === "Make New" && selectedCollectorType === "Email Collector") {
            submitEmailCollector()
        }
    }
    function exportDataToCSV()
    {
        if (selectedCourse != null && selectedCourseSession != null && instructorId.length > 0 && selectedCourseName != null)
            props.navigation("/courseAssignment/signInSheet/?selectedCourse=" + selectedCourse + "&selectedCourseSession=" + selectedCourseSession + "&instructorId=" + instructorId + "&selectedCourseName=" + selectedCourseName + "&sessionScheduleId=" + sessionScheduleId, { state: { selectedCourse: selectedCourse, selectedCourseSession: selectedCourseSession, instructorId: instructorId, selectedCourseName: selectedCourseName, sessionScheduleId: sessionScheduleId }, replace: true });
        else
            alert("No instructor for the selected course and session!")
    }
    function RefreshComponent() {
        window.location.reload();
    }
    
    function getCourseDetails() {
        setIsCourseLoading(true)
        var courseType = process.env.REACT_APP_SERVER_URL_COURSE_TYPE ? process.env.REACT_APP_SERVER_URL_COURSE_TYPE?.toString() : "";
        var getCourseDetails = process.env.REACT_APP_SERVER_URL_GET_COURSES ? process.env.REACT_APP_SERVER_URL_GET_COURSES : "";
        const getCourseData = async () =>
            await fetch(getCourseDetails + courseType, {
                headers: {
                    "Authorization": "Bearer " + token.GetToken()
                }
            })
                .then(res => res.json())
                .then(
                    (result) => {
                        const CourseOptions = result.map((d: { id: any; name: any; }) => ({
                            "value": d.id,
                            "label": d.name
                        }))
                        setCourseNames(CourseOptions);
                        setIsCourseLoading(false)
                    },

                    (error) =>
                    {
                        alert("API server error to load course data!");
                        setIsCourseLoading(false)
                    }
                )
        getCourseData();
    }
    function getSessionDetails(selectedCourseId: string) {
        setIsSessionLoading(true)
        var getSessionDetails = process.env.REACT_APP_SERVER_URL_GET_SESSIONS ? process.env.REACT_APP_SERVER_URL_GET_SESSIONS : "";
        const getSessionData = async () =>
            await fetch(getSessionDetails + selectedCourseId, {
                headers: {
                    "Authorization": "Bearer " + token.GetToken()
                }
            })
                .then(res => res.json())
                .then(
                    (result) => {
                        const options = result.map((d: { id: any; name: any; }) => ({
                            "value": d.id,
                            "label": d.name
                        }))
                        result.map((e: any) => setInstructorId(e.instructorIds))
                        result.map((e: any) => setSessionScheduleId(e.sessionScheduleIds))
                        setSessionNames(options)
                        setIsSessionLoading(false)
                        console.log(sessionNames)
                    },

                    (error) => {
                        alert("API server error to load session data!");
                        setIsSessionLoading(false)
                    }
                )
        getSessionData();
    }
    function getEnrolledUsers(selectedSessionId:string)
    {
        var enrolledUsersUrl = process.env.REACT_APP_SERVER_URL_ENROLLED_USERS ? process.env.REACT_APP_SERVER_URL_ENROLLED_USERS : "";
        const getEnrolledUsers = async () =>
            await fetch(enrolledUsersUrl + selectedCourse + "&sessionId=" + selectedSessionId,
                {
                    headers: {
                        "Authorization": "Bearer " + token.GetToken(),
                    }
                })
                .then(res => res.json())
                .then(
                    (result) => {
                        setEnrolledUsers(result)
                        setenrollUsersSpinnerHidden(true)
                        setSignInTableContainerHidden(false)
                        setSurveyCollectorContainerHidden(false)
                        console.log(enrolledUsers);
                    },

                    (error) => {
                        alert("API server error to load Enrolled users data!");
                        setenrollUsersSpinnerHidden(true)
                    }
                )
        getEnrolledUsers();
    }
    
    function getSurveyDetails() { 
        var getSurveyUrl = process.env.REACT_APP_SERVER_URL_GET_SURVEYS ? process.env.REACT_APP_SERVER_URL_GET_SURVEYS : "";
        const getSurveyData = async () =>
            await fetch(getSurveyUrl, {
                headers: {
                    "Authorization": "Bearer " + token.GetToken()
                }
            })
                .then(res => res.json())
                .then(
                    (result) => {
                        const SurveyOptions = result.map((d: { surveyId: any; surveyName: any; }) => ({
                            "value": d.surveyId,
                            "label": d.surveyName
                        }))
                        setSurveyNames(SurveyOptions);
                        console.log(surveyNames)
                    },

                    (error) => {
                        alert("API server error to load Survey data!");
                    }
                )
        getSurveyData();
    }
    function getCollectorDetailsOnSurvey(selectedSurveyId:string)
    {
        setIsCollectorLoading(true)
        var getCollectorDetails = process.env.REACT_APP_SERVER_URL_GET_COLLECTORS ? process.env.REACT_APP_SERVER_URL_GET_COLLECTORS : "";
        const getCollectorData = async () =>
            await fetch(getCollectorDetails + selectedSurveyId, {
                headers: {
                    "Authorization": "Bearer " + token.GetToken()
                }
            })
                .then(res => res.json())
                .then(
                    (result) => {
                        const options = result.map((d: { collectorId: any; collectorName: any; }) => ({
                            "value": d.collectorId,
                            "label": d.collectorName
                        }))

                        setCollectorNames(options)
                        setIsCollectorLoading(false)
                    },

                    (error) => {
                        alert("API server error to load Collector details data!");
                        setIsCollectorLoading(false)
                    }
                )
        getCollectorData();
    }
    function submitWebLinkCollector()
    {
        var submitWebLinkUrl = process.env.REACT_APP_SERVER_URL_SUBMIT_WEBLINK ? process.env.REACT_APP_SERVER_URL_SUBMIT_WEBLINK : "";
        const getSurveyLink = async () =>
            await fetch(submitWebLinkUrl + selectedSurvey + "&colletorId=" + selectedCollector, {
                headers: {
                    "Authorization": "Bearer " + token.GetToken()
                }
            })
                .then(res => res.json())
                .then(
                    (result) => {
                        setInfoMessage(result.url);
                        setSubmitSpinnerHidden(true)

                        if (result.url !== null)
                        {
                            setLineContainerHidden(false)
                        }                       
                        console.log(infoMessage)
                    },

                    (error) => {
                        alert("API server error to load data!");
                        setSubmitSpinnerHidden(true)
                    }
                )
        getSurveyLink();
    }
    function submitEmailCollector() {
        const emails=checkedEmails.filter(elem => elem != '')
        var submitEmailUrl = process.env.REACT_APP_SERVER_URL_SUBMIT_EMAIL_COLLECTOR ? process.env.REACT_APP_SERVER_URL_SUBMIT_EMAIL_COLLECTOR : "";
        const getEmailMessage = async () =>
            await fetch(submitEmailUrl + selectedSurvey + "&collectorId=" + selectedCollector
                + "&collectorName=" + collectorName + "&subject=" + subjectName + "&bodyMsg=" + bodyMessage + "&emails=" + emails,
                {
                method: "POST",
                headers: {
                    "Authorization": "Bearer " + token.GetToken()
                }
            })
                .then(res => res.json())
                .then(
                    (result) => {
                        setInfoMessage(result.message);
                        if (result.message!== null)
                        {
                            setSubmitSpinnerHidden(true)
                            setLineContainerHidden(false)
                        }
                        console.log(infoMessage)
                    },

                    (error) => {
                        alert("API server error to load data!");
                        setSubmitSpinnerHidden(true)
                    }
                )
        getEmailMessage();
    }
    function createWebLinkCollector() {
        var createWebLinkUrl = process.env.REACT_APP_SERVER_URL_CREATE_WEBLINK_COLLECTOR ? process.env.REACT_APP_SERVER_URL_CREATE_WEBLINK_COLLECTOR : "";
        const createWebLink = async () =>
            await fetch(createWebLinkUrl + selectedSurvey + "&collectorType=weblink" + "&collectorName=" + collectorName ,
                {
                    method: "POST",
                    headers: {
                        "Authorization": "Bearer " + token.GetToken()
                    }
                })
                .then(res => res.json())
                .then(
                    (result) => {
                        setInfoMessage(result.url);
                        if (result.url !== null) {
                            setSubmitSpinnerHidden(true)
                            setLineContainerHidden(false)
                        }
                        console.log(infoMessage)
                    },

                    (error) => {
                        alert("API server error to load data!");
                        setSubmitSpinnerHidden(true)
                    }
                )
        createWebLink();
    }
    
    function onValueChange(e: any) {
        setSelectedOption(e.target.value)
    }
    function onCollectorChange(e: any) {
        setSelectedCollectorType(e.target.value)
    }
    return (
        <div>
            <div className="loading-indicator" hidden={enrollUsersSpinnerHidden}>
                <div className="spinner-border text-primary">
                    <span className="sr-only">Loading...</span>
                </div>
            </div>
            <div className="loading-indicator" hidden={submitSpinnerHidden}>
                <div className="spinner-border text-primary">
                    <span className="sr-only">Loading...</span>
                </div>
            </div>
            <Breadcrumbs route="/courseAssignment/instructor" name="Instructor Tools" />
            <LogoutUser isAdmin={isAdmin} />
            <div className="container">
                <form id="courseForm">
                    <div id="courseIdContainer" >
                        <label htmlFor="courseId-selectized">Select a Course:</label> 
                        <Select placeholder="Select" isLoading={isCourseLoading} options={isCourseLoading ? [] : courseNames} noOptionsMessage={() => 'No options Found'} onChange={handleCourseChange} />
                    </div>
                    {(selectedCourse!==null && selectedCourse!== undefined)&&<div id="courseSessionContainer">
                        <label htmlFor="courseSession-selectized">Select a Course Session:</label>
                        <Select placeholder="Select" isLoading={isSessionLoading} options={isSessionLoading ? [] : sessionNames} noOptionsMessage={() => 'No options Found'} onChange={handleSessionChange}/>
                    </div>}
                </form>
                <div id="signInTableContainer" hidden={signInTableContainerHidden}>
                    <table className="table table-striped">
                        <thead>
                            <tr>
                                <th>✓</th>
                                <th>#</th>
                                <th>First Name</th>
                                <th>Last Name</th>
                                <th>Email</th>
                                <th>Company</th>
                            </tr>
                        </thead>
                        <tbody>
                            {enrolledUsers.map((user, index) =>
                                <tr>
                                    <td><input type="checkbox" className="checkbox" value={user.emailAddress} onChange={handleEmails} /></td>
                                    <td>{index + 1}</td>
                                    <td>{user.firstName}</td>
                                    <td>{user.lastName}</td>
                                    <td>{user.emailAddress}</td>
                                    <td>{user.company}</td>
                                </tr>)}
                        </tbody>
                    </table><br />
                    <div id="buttonContainer">
                        <button className="btn btn-secondary" onClick={exportDataToCSV}>Download Sign In Sheet</button>
                    </div>
                </div> <hr/>
                <div id="surveyCollectorContainer" hidden={surveyCollectorContainerHidden}>
                    <h2>Collectors</h2>
                    {/*<div id="radioType">*/}
                    {/*    <input type="radio" name="type" value="UseExsisting" onChange={handleExsistingRadioClick} />*/}
                    {/*    <label style={{ margin: "10px" }}>Use Exsisting</label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;*/}
                    {/*    <input type="radio" name="type" value="MakeNew" onChange={handleMakeNewRadioClick} />*/}
                    {/*    <label style={{ margin: "10px" }}>Make New</label><hr />*/}
                    {/*</div>*/}
                    {/*<div id="collectorType">*/}
                    {/*    <input type="radio" name="collectorName" value="WebLinkCollector" onChange={handleWebLinkRadioClick} />*/}
                    {/*    <label style={{ margin: "10px" }}>Web Link Collector</label>&nbsp;&nbsp;*/}
                    {/*    <input type="radio" name="collectorName" value="EmailCollector" onChange={handleEmailRadioClick} />*/}
                    {/*    <label style={{ margin: "10px" }}>Email Collector</label>*/}
                    {/*</div>*/}
                    <div className="radio">
                        <div>
                        <label>
                            <input
                                type="radio"
                                value="Use Exsisting"
                                checked={selectedOption === "Use Exsisting"}
                                onChange={onValueChange}
                            />
                            Use Exsisting
                        </label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

                        <label>
                            <input
                                type="radio"
                                value="Make New"
                                checked={selectedOption === "Make New"}
                                onChange={onValueChange}
                            />
                            Make New
                            </label>
                        </div><br/>
                        <div>
                            <label>
                            <input
                                type="radio"
                                value="Web Link Collector"
                                checked={selectedCollectorType === "Web Link Collector"}
                                onChange={onCollectorChange}
                            />
                                Web Link Collector
                            </label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <label>
                                <input
                                    type="radio"
                                    value="Email Collector"
                                    checked={selectedCollectorType === "Email Collector"}
                                    onChange={onCollectorChange}
                                />
                                Email Collector
                            </label>
                        </div>
                    </div><br/>
                    <div id="surveyContainer">
                        <label htmlFor="surveyId-selectized">Select a Survey:</label>
                        <Select placeholder="-- Select a Survey --" options={surveyNames} noOptionsMessage={() => 'No options Found'} onChange={handleSurveyChange} />
                    </div>
                    {((selectedSurvey !== null && selectedSurvey !== undefined) && (selectedOption === "Use Exsisting" && (selectedCollectorType === "Web Link Collector" || selectedCollectorType === "Email Collector"))) && <div id="collectorContainer">
                        <label>Select a Collector:</label>
                            <Select placeholder="Select" isLoading={isCollectorLoading} options={isCollectorLoading ? [] : collectorNames} noOptionsMessage={() => 'No options Found'} onChange={handleCollectorChange} />
                        
                    </div>}
                    {(selectedOption === "Make New" && (selectedCollectorType === "Web Link Collector" || selectedCollectorType === "Email Collector")) && <div id="nameDateContainer">
                        <label>Collector Name:</label><br />
                        <input type="text" style={{ width: '100%' }} onChange={handleCollectorName} id="collectorName" placeholder="ex. 2020 - Vision Collector" />
                        <label>Close Date: (optional)</label><br/>
                        <input type="date" style={{ width: '100%' }} id="closeDate"/>
                    </div>}
                    {((selectedOption === "Make New" || selectedOption === "Use Exsisting") && selectedCollectorType === "Email Collector") && <div id="subjectBodyContainer">
                        <label>Subject</label>
                        <input type="text" onChange={handleSubjectName} style={{ width: '100%' }} id="collectorSubject" placeholder="ex. We want your opinion" />
                        <label>Body</label><br />
                        <textarea onChange={handleBodyMessage} style={{ width: '100%', resize: 'none' }} id="collectorBody" placeholder="ex. Greetings human"></textarea>
                        <p className="warning">Please select the enrollees above using the checkboxes in the table above.</p>
                    </div>}
                    <br />
                    {(selectedOption!=='' && selectedCollectorType!=='')&& <div className="buttonContainer">
                        <button className="btn btn-primary" onClick={handleSubmit}>Submit</button>&nbsp;&nbsp;
                        <button className="btn btn-secondary" onClick={RefreshComponent}>Reset</button>
                    </div>}
                </div> <br/>
                <div id="lineContainer" className="alert alert-info" role="alert" hidden={lineContainerHidden}>
                    <strong>{infoMessage}</strong>
                </div>
            </div>
        </div>
    );
}
//export default InstructorTools;

export function InstructorToolsRouter() {
    const navigate = useNavigate();
    return (<InstructorTools navigation={navigate} />);
}
export default withAuth(InstructorTools)