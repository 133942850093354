import React from 'react'
import { Button, Form } from 'react-bootstrap';
import * as XLSX from 'xlsx';
import { useState } from 'react';
import UserRequestDetails from '../UserRequestDetails/UserRequestDetails';
import ManageToken from '../../Token';
import { useNavigate } from 'react-router-dom';
import { withAuth } from 'react-oidc-context';
import Breadcrumbs from '../Breadcrumbs/Breadcrumbs';
import LogoutUser from '../Logout/Logout';

class UploadFile extends React.Component<any, any> {
    token: any;
    isAdmin = false;
    constructor(props: any) {
        super(props);
        this.token = new ManageToken();
        this.state = {
            uploadDetails: [{
                "isSuccess": false,
                "errorMessage": null,
                "requestId": 0
             }],
            requestName: null,
            selectedFile: null,
            errorMessage: '',
            accessToken: props.accessToken,
            dataDisplaySpinnerHidden: true
        }
        this.handleSubmit = this.handleSubmit.bind(this)
    }

    async componentDidMount() {

    }
   
    onTextChange = (event: any) => {
        this.setState({ requestName: event.target.value })
    };

    // On file select (from the pop up)
    onFileChange = (event: any) => {

        // Update the state
        this.setState({ selectedFile: event.target.files[0] });

    };


    handleSubmit() {
        this.setState({
            dataDisplaySpinnerHidden: false,
        })
        var createRequestURL = process.env.REACT_APP_SERVER_URL_CREATE_REQUEST ? process.env.REACT_APP_SERVER_URL_CREATE_REQUEST : "";
            var data = new FormData();
            data.append("file", this.state.selectedFile);
            const postData = async () =>
                await fetch(createRequestURL + this.state.requestName,
                    {
                        method: "POST",
                        body: data,
                        headers: {
                            "Authorization": "Bearer " + this.token.GetToken()
                        }
                    })

                    .then(res => res.json())
                    .then(
                        (result) => {
                            this.setState({
                                uploadDetails: result
                            }, () => {
                                console.log(this.state.uploadDetails)
                                if (result[0].isSuccess) {
                                    this.setState({
                                        dataDisplaySpinnerHidden: true,
                                    })
                                    this.props.navigation("/courseAssignment/detail/?requestId=" + result[0].requestId, { state: { requestId: result[0].requestId }, replace: true });
                                }
                                else
                                {
                                    this.setState({ errorMessage: result[0].errorMessage }, () => { console.log(this.state.errorMessage) })
                                    this.setState({
                                        dataDisplaySpinnerHidden: true,
                                    })
                                }
                            });   
                                                    
                        },
                   
                        (error) => {
                            this.setState({ errorMessage: error.toString() });
                            this.setState({
                                dataDisplaySpinnerHidden: true,
                            })
                            console.error('There was an error!', error);

                        }
                    )
            postData();
    };
    public render() {

        return (
            <div>
                <div className="loading-indicator" hidden={this.state.dataDisplaySpinnerHidden}>
                    <div className="spinner-border text-primary">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
                <Breadcrumbs route="/courseAssignment/requests" name="Course Enrollment" />
                <LogoutUser isAdmin={this.isAdmin} />
                <div className="container mt-5" >
                    <div className="row form-group">
                        <div className="col-lg-4">
                            <div className="bg-light-gray card p-3">
                                <h3>Create a New Request</h3>
                                        <label className="">Request Name:</label>
                                        <input type="text" onChange={this.onTextChange} value={this.state.requestName} />
                                <br />
                                        <input type="file" onChange={this.onFileChange} />
                                        <br /><br />
                                        <input type="submit" className="btn btn-primary" id="validate-btn" value="Submit" onClick={this.handleSubmit} />
                                       &nbsp; <label>{this.state.errorMessage}</label>

                            </div>
                        </div>
                        <div className="col-md-7 col-lg-5">
                            <label className="control-label"> <b> Instructions: </b>  </label>
                            <li>Download and fill in the <a href="https://www.commscope.com/globalassets/digizuite/954364-CommscopeUniversityUtilityTemplate.xlsx"> <b>New Request template</b></a>, in order to upload the proper file type and format.</li>
                            {/*<ol>*/}
                            {/*    <li>*/}
                            {/*        Select the "Choose file..." button to open the file upload window*/}
                            {/*    </li>*/}
                            {/*    <li>*/}
                            {/*        Browse to your prepared .xlsx or .xls request file*/}
                            {/*        <ul>*/}
                            {/*            <li>Download the <a href="https://www.commscope.com/globalassets/digizuite/954364-CommscopeUniversityUtilityTemplate.xlsx"> New Request template</a></li>*/}

                            {/*        </ul>*/}
                            {/*    </li>*/}
                            {/*    <li>*/}
                            {/*        Click 'Submit' to submit the file*/}

                            {/*    </li>*/}
                            {/*</ol>*/}
                        </div>
                    </div>

                </div>
            </div>
        );

    }

}
export function UploadFileRouter() {
    const navigate = useNavigate();
    return (<UploadFile navigation={navigate} />);
}
export default withAuth(UploadFile)
