import { CellDirective, CellModel, CellsDirective, CellStyleModel, RangeDirective, RangeModel, RangesDirective, RowDirective, RowsDirective, SheetDirective, SheetsDirective, SpreadsheetComponent } from '@syncfusion/ej2-react-spreadsheet';
import { useEffect, useRef, useState } from 'react';
import { isArrowFunction } from 'typescript';
import ManageToken from '../../Token';
import { useLocation } from 'react-router-dom'
import Breadcrumbs from '../Breadcrumbs/Breadcrumbs';
import LogoutUser from '../Logout/Logout';
import { ColumnDirective, ColumnsDirective, columnWidthChanged } from '@syncfusion/ej2-react-grids';

export default function SignInSheetComponent(props: any) {
    const isAdmin = false;
    let spreadsheet: SpreadsheetComponent | null;
    const cellGray: CellStyleModel = { fontSize: '11pt', fontWeight: "bold", backgroundColor: '#D0D0D0' }
    const cellWhite: CellStyleModel = { fontSize: '11pt', fontWeight: "bold", backgroundColor: 'white' }
    const onCreated = () => {
        //spreadsheet?.cellFormat({ fontSize: '11pt', fontWeight: "bold", backgroundColor: '#D0D0D0' }, "c12:f12");
    }
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const courseId = useRef(searchParams.get("selectedCourse")?.toString());
    const sessionId = useRef(searchParams.get("selectedCourseSession")?.toString());
    const courseName = useRef(searchParams.get("selectedCourseName")?.toString());
    const instructorId = useRef(searchParams.get("instructorId")?.toString());
    const sessionScheduleId = useRef(searchParams.get("sessionScheduleId")?.toString());

    const [enrolledUsers, setEnrolledUsers] = useState([{
        "firstName": '',
        "lastName": '',
        "emailAddress": '',
        "company": ''
    }])

    const [enrolledUserFormattedData, setEnrolledUserFormattedData] = useState([])

    const [instructorName, setInstructorName] = useState('')
    const [classDate, setclassDate] = useState('')
    const [venueName, setvenueName] = useState('')
    const [dataDisplayHidden, setDataDisplayHidden]=useState(true)
    const token = new ManageToken();
    useEffect(() => {
        getSignInSheetData()

    }, [])


    function getSignInSheetData() {
        setDataDisplayHidden(false)
        var signInSheetUrl = process.env.REACT_APP_SERVER_URL_GET_SHEET_DATA ? process.env.REACT_APP_SERVER_URL_GET_SHEET_DATA : "";
        const getSignInSheetData = async () =>
            await fetch(signInSheetUrl + courseId.current + "&sessionId=" + sessionId.current + "&instructorId=" + instructorId.current + "&sessionSheduleId=" + sessionScheduleId.current,
                {
                    headers: {
                        "Authorization": "Bearer " + token.GetToken(),
                    }
                })
                .then(res => res.json())
                .then(
                    (result) => {
                        let tempArr: any = []
                        setEnrolledUsers(result.enrolledUsers)

                        result.enrolledUsers.map((data: any, index: number) => {
                            let temp = {
                                "First Name": data.firstName,
                                "Last Name": data.lastName,
                                "Email Address": data.emailAddress,
                                "Company": data.company
                            }
                            tempArr.push(temp)
                        }
                        )

                        setEnrolledUserFormattedData(tempArr)
                        
                        setInstructorName(result.instructorName)
                        setclassDate(result.classDate)
                        setvenueName(result.venueName)
                        setDataDisplayHidden(true)
                        //console.log(enrolledUsers);
                    },

                    (error) => {
                        alert("API server error to load data!");
                    }
                )
        getSignInSheetData();

    }



    return (<div>
        <div className="loading-indicator" hidden={dataDisplayHidden}>
            <div className="spinner-border text-primary">
                <span className="sr-only">Loading...</span>
            </div>
        </div>
        <Breadcrumbs route="/courseAssignment/instructor" name="Instructor Tools" />
        <LogoutUser isAdmin={isAdmin} />
        <SpreadsheetComponent ref={(ss => spreadsheet = ss)} allowSave={true} allowCellFormatting={true} created={onCreated}
            saveUrl="https://ej2services.syncfusion.com/production/web-services/api/spreadsheet/save">
            <SheetsDirective >
                <SheetDirective name="Sign-In Sheet">
                    <RangesDirective>
                        <RangeDirective dataSource={enrolledUserFormattedData} startCell="c12" />
                    </RangesDirective>

                    <RowsDirective>

                        <RowDirective index={0}>
                            <CellsDirective>
                                <CellDirective colSpan={10} index={3} value='COMMSCOPE Course Sign-In Sheet' style={{ fontSize: '35pt', fontWeight: "bold", fontFamily: "Calibri" }}></CellDirective>
                            </CellsDirective>
                        </RowDirective>
                        <RowDirective index={2}>
                            <CellsDirective>
                                <CellDirective index={3} colSpan={4} value='Company Name' style={cellGray} />
                                <CellDirective index={7} colSpan={6} />
                            </CellsDirective>
                        </RowDirective>
                        <RowDirective>
                            <CellsDirective>
                                <CellDirective index={3} colSpan={4} value='Class Date' style={cellGray} />
                                <CellDirective index={7} colSpan={6} value={classDate} style={cellWhite} />
                            </CellsDirective>
                        </RowDirective>
                        <RowDirective>
                            <CellsDirective>
                                <CellDirective index={3} colSpan={4} value='Class Location' style={cellGray} />
                                <CellDirective index={7} colSpan={6} value={venueName} style={cellWhite} />
                            </CellsDirective>
                        </RowDirective>
                        <RowDirective>
                            <CellsDirective>
                                <CellDirective index={3} colSpan={4} value='COMMSCOPE Sales Order Number' style={cellGray} />
                                <CellDirective index={7} colSpan={6} />
                            </CellsDirective>
                        </RowDirective>
                        <RowDirective>
                            <CellsDirective>
                                <CellDirective index={3} colSpan={4} value='Instructor Name' style={cellGray} />
                                <CellDirective index={7} colSpan={6} value={instructorName} style={cellWhite} />
                            </CellsDirective>
                        </RowDirective>
                        <RowDirective>
                            <CellsDirective>
                                <CellDirective index={3} colSpan={4} value='Course Name' style={cellGray} />
                                <CellDirective index={7} colSpan={6} value={courseName.current} style={cellWhite} />
                            </CellsDirective>
                        </RowDirective>
                        <RowDirective index={10}>
                            <CellsDirective>
                                <CellDirective index={4} colSpan={5} value='Student Information' style={cellWhite} />

                            </CellsDirective>
                        </RowDirective>
                        <RowDirective index={enrolledUsers.length + 14}>
                            <CellsDirective>
                                <CellDirective index={4} colSpan={5} value='CommScope Course Sign-in - FRM 0105 (Reference QMS0061)' wrap={false} style={{ fontSize: '9pt', fontFamily: "Calibri" }}></CellDirective>
                                <CellDirective index={10} colSpan={4} value='Issue 1.3 - 2/24/2017' wrap={false} style={{ fontSize: '9pt', fontFamily: "Calibri" }}></CellDirective>

                            </CellsDirective>
                        </RowDirective>
                    </RowsDirective>

                </SheetDirective>
            </SheetsDirective>
        </SpreadsheetComponent>
    </div>
    );
}