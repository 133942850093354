import { useAccount, useMsal } from "@azure/msal-react"; 
interface LogoutUserProps {
    isAdmin: boolean;
}
export default function LogoutUser({ isAdmin }: LogoutUserProps) {
    //const auth = useAuth();
    const { instance, accounts } = useMsal();
    async function Logout() {
       
        const account = instance.getAllAccounts()[0]
        const logoutHint = account.idTokenClaims?.login_hint;

        instance.logoutRedirect({
            account: instance.getActiveAccount(),
            idTokenHint: account.idToken,
            postLogoutRedirectUri: process.env.REACT_APP_B2C_POST_LOGOUT_URI ? process.env.REACT_APP_B2C_POST_LOGOUT_URI.toString() : ""
            //onRedirectNavigate: false
        });
    }    
    return (
        <div className="container">
            <div className="row">
                {!isAdmin && (
                    <>
                        <div className="col-lg-11"><a href="#">&lt; University Home</a></div>
                        <div className="col-lg-1"><div style={{ float: 'right' }}><button className="btn btn-secondary" title="Logout" onClick={Logout}>Logout</button></div></div>
                    </>
                    
                )}
                {isAdmin && (
                        <div className="col-lg-12"><div style={{ float: 'right' }}><button className="btn btn-secondary" title="Logout" onClick={Logout}>Logout</button></div></div>
                )}
            </div>
        </div>

    );
}