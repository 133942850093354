import React, { useState } from 'react';
import ReactDOM, { render } from 'react-dom';
import './index.css';
import App from './App';
import { registerLicense } from '@syncfusion/ej2-base';
import Requests from './components/Requests/Requests';
import { User } from 'oidc-client-ts';
import { PublicClientApplication, EventType, InteractionRequiredAuthError } from "@azure/msal-browser";
import { loginRequest, msalConfig } from "./authConfig";


// Registering Syncfusion license key
registerLicense('Ngo9BigBOggjHTQxAR8/V1NCaF5cXmZCf1FpRmJGdld5fUVHYVZUTXxaS00DNHVRdkdnWXddcXRVR2RYUEVyV0I=');
const myMSALObj = new PublicClientApplication(msalConfig);
/*var oidcConfig = {    
    authority: process.env.REACT_APP_AUTHORITY ? process.env.REACT_APP_AUTHORITY.toString() : "",
    client_id: process.env.REACT_APP_SERVER_URL_CLIENT_ID ? process.env.REACT_APP_SERVER_URL_CLIENT_ID.toString() : "",
    client_secret: process.env.REACT_APP_SERVER_URL_CLIENT_SECRET ? process.env.REACT_APP_SERVER_URL_CLIENT_SECRET.toString() : "",
    redirect_uri: process.env.REACT_APP_SERVER_URL_REDIRECT_URI ? process.env.REACT_APP_SERVER_URL_REDIRECT_URI.toString() : "",
    response_type: "code",
    filterProtocolClaims: true,
    loadUserInfo: true,
    metadata: {
        authorization_endpoint: process.env.REACT_APP_AUTHORIZATION_ENDPOINT ? process.env.REACT_APP_AUTHORIZATION_ENDPOINT.toString() : "",
        token_endpoint: process.env.REACT_APP_TOKEN_ENDPOINT ? process.env.REACT_APP_TOKEN_ENDPOINT.toString() : "",
        userinfo_endpoint: process.env.REACT_APP_USERINFO_ENDPOINT ? process.env.REACT_APP_USERINFO_ENDPOINT.toString() : ""
    },
    post_logout_redirect_uri: process.env.REACT_APP_NETIQ_LOGOUT ? process.env.REACT_APP_NETIQ_LOGOUT.toString() : "",
    scope: "openid CommScopeProfile",
    automaticSilentRenew: false,
};

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);


const onSignInCallBack = (_user: User | void): void => {
    console.log("onSignInCallBack");
    window.history.replaceState(
        {},
        document.title,
        window.location.pathname
    )
    window.sessionStorage.setItem("AuthorizationToken", (_user) ? _user?.access_token : "");
    window.location.reload();
}

root.render(
    <AuthProvider {...oidcConfig} onSigninCallback={onSignInCallBack}>
        <App />
    </AuthProvider>,
);*/
ReactDOM.render(
    <React.StrictMode>
        <App instance={myMSALObj} />
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
/*reportWebVitals();*/
